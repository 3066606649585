import React from "react";

import Typography from "@ui-kit/Typography";
import { useCss } from "../styles";

const AuthorizationTermsPage: React.FunctionComponent = () => {
  const css = useCss();

  return (
    <div css={css.legalPage}>
      <Typography variant="h3" gutterBottom align="center">
        HIPPA Authorization
      </Typography>
      <Typography variant="body1" paragraph>
        If you have questions or concerns about this authorization, you should
        contact us by emailing{" "}
        <a href="mailto:support@parentdayout.com">support@parentdayout.com</a>.
        By accessing the website you accept and agree to be bound, without
        limitation or qualification by this authorization. If you do not accept
        any of the terms stated here, do not use the website.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Parent Day Out Services
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out offers services ("Parent Day Out Services"), such as
        helping you to find and learn about schools, booking tour appointments,
        booking playdates, as well as, if you choose to do so, storing health
        history forms and other health-related information ("Registration
        Forms").
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Parent Day Out Play Day Reservations
      </Typography>
      <Typography variant="body1" paragraph>
        An important part of preparing for a day of care is ensuring that your
        care provider ("Licensed Child Care Center") has the needed information
        to deliver a rich, meaningful and safe experience for your child.
        Licensed child care centers are required to collect specific medical and
        non-medical information to meet state and local licensing requirements.
        If you choose to do so, this information ("Registration Forms") may be
        uploaded by you and stored by Parent Day Out as part of your user
        account.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Registration Forms
      </Typography>
      <Typography variant="body1" paragraph>
        As part of providing Parent Day Out Services, you may upload and Parent
        Day Out may store health history forms and other-health related
        information ("Registration Forms"). Under a federal law called the
        Health Insurance Portability and Accountability Act (“HIPAA”), some of
        this health and health-related information may be considered “protected
        health information” or “PHI”.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Non-Protected Health Information
      </Typography>
      <Typography variant="body1" paragraph>
        As a condition of creating your Parent Day Out account, you are required
        to read and agree to Parent Day Out’s Privacy Policy. Parent Day Out’s
        Privacy Policy explains how Parent Day Out processes and shares
        information received from you that is not covered by HIPAA (“Non-PHI”).
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Safeguards for PHI
      </Typography>
      <Typography variant="body1" paragraph>
        HIPAA protects the privacy and security of your PHI by limiting the uses
        and disclosures of PHI. Under certain circumstances described in HIPAA,
        an individual needs to sign an Authorization form before a Covered
        Entity, like Your Healthcare Provider(s), can disclose protected health
        information to a third party.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Your PHI Authorization
      </Typography>
      <Typography variant="body1" paragraph>
        By uploading Registration Forms to the Parent Day Out website, you give
        your permission to Parent Day Out to retain your PHI and use and/or
        disclose your PHI in the same way that you have agreed that your Non-PHI
        can be used and disclosed.
      </Typography>
      <Typography variant="body1" paragraph>
        Specifically, you agree that Parent Day Out can use your PHI to:
      </Typography>
      <Typography variant="body1" paragraph component="div">
        <ul>
          <li>enable and customize your use of the Parent Day Out Services;</li>
          <li>
            provide you alerts or other Parent Day Out Services regarding future
            appointments;
          </li>
          <li>
            notify you regarding schools we think you may be interested in
            learning more about;
          </li>
          <li>
            share information with you regarding services, products or resources
            about which we think you may be interested in learning more;
          </li>
          <li>
            provide you with updates and information about the Parent Day Out
            Services;
          </li>
          <li>
            market to you about Parent Day Out and third party products and
            services;
          </li>
          <li>conduct analysis for Parent Day Out's business purposes;</li>
          <li>support development of the Parent Day Out Services;</li>
          <li>
            create de-identified information and then use and disclose this
            information in any way permitted by law, including to third parties
            in connection with their commercial and marketing efforts.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" paragraph>
        You also agree that Parent Day Out can disclose your PHI to:
      </Typography>
      <Typography variant="body1" paragraph component="div">
        <ul>
          <li>
            third parties assisting Parent Day Out with any of the uses
            described above;
          </li>
          <li>
            a third party as part of a potential merger, sale or acquisition of
            Parent Day Out;
          </li>
          <li>
            our business partners who assist us by performing core services
            (such as hosting, billing, fulfillment, or data storage and
            security) related to the operation or provision of our services;
          </li>
          <li>
            organizations that collect, aggregate and organize your information
            so they can make it more easily accessible to your providers.
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Redisclosure
      </Typography>
      <Typography variant="body1" paragraph>
        If Parent Day Out discloses your PHI, Parent Day Out will require that
        the person or entity receiving your PHI agrees to only use and disclose
        your PHI to carry out its specific business obligations to Parent Day
        Out, or for the permitted purpose of the disclosure (as described
        above). Parent Day Out cannot, however, guarantee that any such person
        or entity to which Parent Day Out discloses your PHI or other
        information will not re-disclose it in ways that you or we did not
        intend or permit.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Expiration and Revocation of Authorization
      </Typography>
      <Typography variant="body1" paragraph>
        Your Authorization remains in effect as long as you choose to store
        "Registration Forms" as part of your user account.
      </Typography>
      <Typography variant="body1" paragraph>
        YOU CAN CHANGE YOUR MIND AND REVOKE THIS AUTHORIZATION AT ANY TIME AND
        FOR ANY (OR NO) REASON
      </Typography>
      <Typography variant="body1" paragraph>
        If you wish to revoke this Authorization, you must delete all stored
        Registration Forms so that they are no longer part of your user account.
        Your decision not to execute this Authorization or to revoke it at any
        time will not affect your ability to use certain of the Parent Day Out
        Services. A Revocation of Authorization is effective after you delete
        all Registration Forms linked to your account but it does not have any
        effect on Parent Day Out's prior actions taken in reliance on the
        Authorization before revoked.
      </Typography>
      <Typography variant="body1">
        Your Revocation of Authorization does not affect Parent Day Out's use of
        your Non-PHI.
      </Typography>
    </div>
  );
};

export default AuthorizationTermsPage;
