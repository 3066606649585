import React from "react";

import Typography from "@ui-kit/Typography";
import { useCss } from "../styles";

const PrivacyTermsPage: React.FC = () => {
  const css = useCss();

  return (
    <div css={css.legalPage}>
      <Typography variant="h3" gutterBottom align="center">
        Privacy policy
      </Typography>
      <Typography variant="body1" paragraph align="justify">
        This privacy statement discloses the privacy practices for
        ParentDayOut.com, a website owned and operated by Parent Day Out Inc. If
        you have questions or concerns about this privacy policy, you should
        contact us by emailing{" "}
        <a href="mailto:support@parentdayout.com">support@parentdayout.com</a>.
        By accessing the website you accept and agree to be bound, without
        limitation or qualification by this policy. If you do not accept any of
        the terms stated here, do not use the website.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Information Collection and Use
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out Inc. may collect information from our users at several
        different points on our website. Parent Day Out Inc. is the sole owner
        of the information collected on this site. We will not sell, share or
        rent this information to others in ways other than as disclosed in this
        statement.
      </Typography>
      <Typography variant="body1" paragraph>
        We may share aggregated demographic information with our partners and
        sponsors. This is not linked to any personally identifiable information.
      </Typography>
      <Typography variant="body1" paragraph>
        We may share your email address with our partners to offer you programs
        and services we are jointly promoting. Please note, however, if such
        communications include a link to a vendor’s website or a co-branded
        partner page and you submit personal information to such vendor’s
        website or a co-branded partner page, then the privacy policies and
        practices of such vendor’s website or a co-branded partner page governs
        the use of your personal information.
      </Typography>
      <Typography variant="body1" paragraph>
        We may release information provided by a user if a subpoena, order, or
        demand from a governmental agency or court requires disclosure. We may
        also release information provided by a user if Parent Day Out in its
        sole discretion determines that release may assist in protecting the
        health, safety, or welfare of another. However, Parent Day Out has no
        obligation to exercise such discretion.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Cookies
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out Inc. may deposit cookies on users’ hard drives. A cookie
        is a piece of data stored on the user’s hard drive containing
        information about the user. By setting a cookie on our site, a user does
        not have to log in a password more than once and saves time while on our
        site. Users who reject the cookie may still use ParentDayOut.com, but
        they may be limited in some areas of our site.
      </Typography>
      <Typography variant="body1" paragraph>
        Cookies can also enable us to better understand the interests of our
        users to enhance their experience. Some of the information about how our
        users experience our site, such as page views and specific pages
        visited, may be logged by a third-party marketing company on our behalf,
        using cookies and Web beacons. If used, web beacons help us understand
        information such as what browsers our users have so that we are able to
        develop our site to best support our users. We control how this data may
        and may not be used.
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out may also work with third-party media measurement services
        that enable advertisers, publishers and agencies to view audience
        reports for websites. They use certain data, including IP address and
        information from cookies to aggregate Internet activity to produce these
        reports and analysis.
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out may also use Google Analytics to help analyze how
        visitors use our site. Google Analytics uses cookies to collect standard
        internet log information and visitor behavior information in an
        anonymous form. For more information on how Google collects and
        processes this data, please{" "}
        <a
          href="http://www.google.com/policies/privacy/partners/"
          target="_blank"
          rel="noopener noreferrer"
        >
          click here
        </a>
        .
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out may also work with third-party companies to serve ads
        when you visit{" "}
        <a href="//parentdayout.com" target="_blank" rel="noopener noreferrer">
          www.ParentDayOut.com
        </a>
        . These companies may use information (not including your name, address,
        email address or telephone number) about your visits to{" "}
        <a href="//parentdayout.com" target="_blank" rel="noopener noreferrer">
          www.ParentDayOut.com
        </a>{" "}
        in order to provide advertisements on{" "}
        <a href="//parentdayout.com" target="_blank" rel="noopener noreferrer">
          www.ParentDayOut.com
        </a>
        , other websites and other forms of media about goods and services that
        may be of interest to you.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Log Files
      </Typography>
      <Typography variant="body1" paragraph>
        We use IP addresses to analyze trends, administer the site, track users’
        movement and gather broad demographic information for aggregate use. IP
        addresses are not linked to personally identifiable information.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Links
      </Typography>
      <Typography variant="body1" paragraph>
        This website may contain links to other sites. Please be aware that
        Parent Day Out Inc. is notresponsible for the privacy practices of these
        other sites. When users leave our site, we encourage them to be aware of
        and to read the privacy statements of each and every website that
        collects personally identifiable information. This privacy statement
        applies solely to information collected by this website.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        User Surveys
      </Typography>
      <Typography variant="body1" paragraph>
        From time to time, we may request information from users via surveys.
        Participation in these surveys is voluntary and the user therefore has a
        choice whether or not to disclose this information. Information
        requested may include contact information (such as name, phone number,
        and address), and demographic information (such as ZIP code and child’s
        grade level).
      </Typography>
      <Typography variant="body1" paragraph>
        Survey information will be used for the purposes improving the use and
        satisfaction of this site. The information you provide in response to
        survey questions may be shared, but only in the aggregate or
        anonymously, with advertisers and partners, unless we notify you
        otherwise at the time of collection.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Email Referral Service
      </Typography>
      <Typography variant="body1" paragraph>
        If users elect to use our email referral service (“Share Profile”), for
        informing a friend about our site, we ask them for the friend’s name and
        email address. Parent Day Out Inc. may automatically send the friend a
        one-time email inviting them to visit the site.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Security
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out Inc. has implemented commercially reasonable technical
        and organizational measures designed to secure your personal information
        from accidental loss and from unauthorized access, use, alteration or
        disclosure. When users submit sensitive information via the website,
        Parent Day Out Inc. uses commercially reasonable efforts to protect
        their information both online and offline. However, we cannot guarantee
        that unauthorized third parties will never be able to defeat those
        measures or use your personal information for improper purposes. Thus,
        you acknowledge that you provide your personal information at your own
        risk.
      </Typography>
      <Typography variant="body1" paragraph>
        If and when a form asks users to enter sensitive information, that
        information will be encrypted and protected with encryption software
        called SSL.{" "}
        <a
          href="https://www.openssl.org/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Learn more about SSL
        </a>
        .
      </Typography>
      <Typography variant="body1" paragraph>
        While we use SSL encryption to protect sensitive information online, we
        will use commercially reasonable efforts to protect personal user
        information offline. Only employees who need the information to perform
        a specific job will be granted access to personally identifiable
        information. Furthermore, all employees are kept up to date on our
        security and privacy practices. The servers that are used to store
        personally identifiable information are kept in a secure environment.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Compromise of Security
      </Typography>
      <Typography variant="body1" paragraph>
        In the event that personal information is compromised as a result of a
        breach of security, Parent Day Out Inc. will make a best effort to
        notify those persons whose personal information has been compromised, in
        accordance with the notification procedures set forth in this Privacy
        Statement, or as otherwise required by applicable law.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Correction / Updating Personal Information
      </Typography>
      <Typography variant="body1" paragraph>
        If a user’s personally identifiable information changes or if a user no
        longer desires our service, we will endeavor to provide a way to
        correct, update or remove that user’s personal data provided to us.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Notification of Changes
      </Typography>
      <Typography variant="body1" paragraph>
        As we continue to develop our website, this privacy policy likely will
        change. We therefore encourage you to refer to this policy on an ongoing
        basis so that you understand our current privacy policy. You agree to
        review this policy periodically since such changes, modifications,
        additions or deletions shall be effective immediately upon posting and
        your subsequent use after such posting shall conclusively be deemed to
        be acceptance by you of such changes, modifications or deletions.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Login Security
      </Typography>
      <Typography variant="body1" paragraph>
        When you choose a password, the password is stored on a protected Parent
        Day Out Inc. server. However, because Internet communications are
        generally not secure, you should be aware that when you send your
        password across the Internet to us, there is a possibility that an
        experienced hacker could intercept your password. However, we have no
        evidence to date that password information presents a serious security
        threat to warrant using a secure shell for this transaction.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        A Notice About Advertisers
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out may allow advertising by third parties. Parent Day Out
        does not control linking practices of advertisers or sponsors and does
        not monitor or claim responsibility for any content or privacy practices
        of such website. Once you have clicked on an advertisement and have left
        the Parent Day Out site, you must read the privacy policy of the
        advertiser or sponsor to see how your personal information will be
        handled.
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out may offer contests, sweepstakes, and promotions that are
        sponsored by third-party advertisers or sponsors. These third parties
        may obtain personally identifiable information that visitors voluntarily
        submit to participate in the activity. Parent Day Out does not control
        the third parties’ use of information.
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out may user a third party advertising server (such as Double
        Click) to display ads on our site. Some of these ads may contain cookies
        that are set by third parties (advertisers, sponsors). In addition, we
        may allow third parties to place cookies or related tools on our site to
        collect data that may be used to serve advertisements elsewhere (i.e. on
        other websites). The Parent Day Out privacy policy does not cover any
        use of information that a third-party may have collected from you.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        A Notice About External Links
      </Typography>
      <Typography variant="body1" paragraph>
        You may find links from Parent Day Out to independently owned,
        controlled, and/or managed website whose content we believe will be of
        possible interest and value to our visitors. Additionally you may see
        links from advertisers, sponsors and other partners that may use Parent
        Day Out’ logo as part of a co-branding agreement.
      </Typography>
      <Typography variant="body1" paragraph>
        These links may represent cooperative projects or mutual links
        established with the organizations connected with these sites. We don’t
        control the content of these links. These other sites may send you their
        own cookies, collect data, or solicit personal information. While we
        identify sponsor and advertiser areas of our websites, we do not control
        these cookies or the content of these third parties.
      </Typography>
      <Typography variant="body1" paragraph>
        In the future, Parent Day Out may partner with other companies to
        provide certain services. In some cases, you may see Parent Day Out’
        logo on another company’s site. Parent Day Out does not share any of
        your personally identifiable information with these partners and they do
        not share any information with us.
      </Typography>
      <Typography variant="body1" paragraph>
        Once you leave our servers (you can generally tell where you are by
        checking the URL), the use of any information you provide is governed by
        the privacy policy of the operator of the site you are visiting. That
        policy may differ from ours. If you can’t find the privacy policy of any
        of these sites via a link from that site’s home page, you should contact
        the site directly for more information.
      </Typography>
      <Typography variant="body1" paragraph>
        We specifically recommend that parents or teachers monitor any new sites
        before children connect to them.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Our Commitment to Children’s Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        Protecting the privacy of young children is especially important. For
        that reason, Parent Day Out does not knowingly collect personal
        information through the website submitted by persons under 13 years of
        age, and no part of our website is directed to persons under 13. If you
        are under 13 years of age, then please do not use or access the website
        without adult supervision. If Parent Day Out learns that personally
        identifiable information of persons under 13 years of age has been
        collected through the website without verified parental/guardian
        consent, then Parent Day Out will make a best effort to delete this
        information.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Notification Procedures
      </Typography>
      <Typography variant="body1">
        It is the policy of Parent Day Out to provide notifications, whether
        such notifications are required by law or are for marketing or other
        business related purposes, to our customers or website users, via email
        notice, written or hard copy notice, or through conspicuous posting of
        such notice on our website page,{" "}
        <a href="//parentdayout.com" target="_blank" rel="noopener noreferrer">
          www.ParentDayOut.com
        </a>
        , as determined by Parent Day Out in its sole discretion.
        Notwithstanding the foregoing, Parent Day Out reserves the right to
        determine the form and means of providing notifications to our customers
        or website users.
      </Typography>
    </div>
  );
};

export default PrivacyTermsPage;
