import React from "react";

import Typography from "@ui-kit/Typography";
import { useCss } from "../styles";

const OndemandTermsPage: React.FunctionComponent = () => {
  const css = useCss();

  return (
    <div css={css.legalPage}>
      <Typography variant="h3" gutterBottom align="center">
        Childcare booking service
      </Typography>
      <Typography variant="body1" paragraph>
        By booking care through the Parent Day Out platform, you agree to grant,
        without limitation or qualification, the following parental consents to
        the facility that is caring for your child. If do not grant any of the
        consents stated here, do not use the on-demand care service.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Emergency Treatment Consent
      </Typography>
      <Typography variant="body1" paragraph>
        In the event of illness or injury which requires emergency treatment,
        the facility has my permission to administer emergency medical treatment
        to my child [in most emergencies 911 is called and parent is notified as
        soon as possible]. I agree that the cost of any emergency medical
        treatment and related transportation for the child will be my
        responsibility.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Emergency Transportation Consent
      </Typography>
      <Typography variant="body1" paragraph>
        In the event of illness or injury which requires emergency treatment,
        the facility has my permission to secure emergency transportation for my
        child [in most emergencies 911 is called and parent is notified as soon
        as possible]. I agree that the cost of any emergency medical treatment
        and related transportation for the child will be my responsibility.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Photography Consent
      </Typography>
      <Typography variant="body1" paragraph>
        I give permission for my child's image to be used by facility only for
        documenting my child's progress and for communication with me. I also
        understand that while parents/guardians are permitted to take pictures
        of their child in the classroom, pictures that include other children
        should not be posted to social media. If Parent Day Out or center
        management is made aware of such postings, then disenrollment from the
        on-demand care program is possible.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Leaving Premises Consent
      </Typography>
      <Typography variant="body1" paragraph>
        Under staff supervision, I give permission for my child to leave the
        facility on neighborhood excursions such as for playtime, exercise and
        educational purposes. I understand that infants and young toddlers will
        be taken in a buggy or a stroller.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Behavior Management Policy Consent
      </Typography>
      <Typography variant="body1" paragraph>
        If your child is having difficulty and is unable to manage his or her
        behavior, the center may contact you and ask you to intervene or remove
        the child from the facility. This will be used only as a last resort if
        all other means to help your child control his or her behavior have
        failed (for a complete behavior management policy, please contact the
        facility).
      </Typography>
      <Typography variant="body1" paragraph>
        Centers prohibit the following discipline methods:
      </Typography>
      <Typography variant="body1" paragraph component="div">
        <ul>
          <li>
            subjecting children to cruel or severe punishment such as shaming,
            humiliation, threats, derogatory remarks, verbal or physical abuse,
            neglect, or abusive treatment including any forcible restraining,
            hitting inflicted in any manner upon the body, shaking, jerking,
            squeezing, spanking, yelling, choking;
          </li>
          <li>
            labeling (“bad” girl or boy) or otherwise implying that the child is
            the problem, instead of the behavior;
          </li>
          <li>using bribes, false threats, or false choices;</li>
          <li>
            depriving children of outdoor time, unrelated activities such as
            special events;
          </li>
          <li>
            depriving children of meals or snacks; force feeding children or
            otherwise making them eat against their will, or in any way using
            food as a consequence. Neither will food be used as a reward to get
            a child to behave in a certain way;
          </li>
          <li>
            retaliating or doing to the child what he or she did to someone
            else;
          </li>
          <li>
            imposing punishment for soiling, wetting, or not using the toilet;
            forcing a child to remain in soiled clothing or to remain on the
            toilet, or using any other unusual or excessive practices for
            toileting;
          </li>
          <li>
            confining a child to a high chair, crib, or any other piece of
            equipment for an extended period of time in lieu of supervision or
            as punishment;
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Child Illness Policy Consent
      </Typography>
      <Typography variant="body1" paragraph>
        In consideration of other families, sick children are not permitted to
        use the center. The center will contact you if your child becomes ill
        and will keep your child comfortable in a quiet area until you can
        arrive to take your child home. We ask that for your child's comfort and
        to reduce the risk of contagion, children be picked up within 1 hour of
        notification.
      </Typography>
      <Typography variant="body1" paragraph>
        Children with the following symptoms may not attend the center:
      </Typography>
      <Typography variant="body1" paragraph component="div">
        <ul>
          <li>
            Illness that poses a risk of spread of harmful disease to others;
          </li>
          <li>
            Illness that prevents the child from participating comfortably in
            program activities;
          </li>
          <li>
            Illness that results in a greater need for care than our staff can
            provide without compromising the health and safety of other
            children;
          </li>
          <li>
            Fever (100° axillary (armpit), 101° orally, 102° aural/ear)* and
            behavior change or other signs and symptoms, e.g., sore throat,
            rash, vomiting, diarrhea, lethargy, irritability, constant crying,
            difficulty breathing;
          </li>
          <li>
            Diarrhea — more watery stools or decreased form of stool that is not
            associated with change of diet. Exclusion is required for all
            diapered children whose stool is not contained in the diaper and
            toilet-trained children if the diarrhea is causing “accidents.”
            Diapered children with diarrhea will be excluded if the stool
            frequency exceeds 2 or more stools above normal for that child;
          </li>
          <li>
            Blood or mucus in the stools not explained by dietary change,
            medication, or hard stools, and/or uncontrolled, unformed stools
            that cannot be contained in a diaper/underwear or toilet. Special
            circumstances that require specific exclusion criteria include the
            following;
          </li>
          <li>
            Toxin-producing E coli or Shigella infection, until the diarrhea
            resolves and the test results of 2 stool cultures are negative for
            these organisms;
          </li>
          <li>
            Salmonella serotype Typhi infection, until diarrhea resolves. In
            children younger than 5 years with Salmonella serotype Typhi, 3
            negative stool cultures are required;
          </li>
          <li>
            Vomiting more than 2 times in the previous 24 hours unless the
            vomiting is determined to be caused by a non-communicable condition
            and the child is not in danger of dehydration;
          </li>
          <li>
            Mouth sores with drooling unless the child’s medical provider or
            local health department authority states that the child is
            noninfectious;
          </li>
          <li>
            Abdominal pain that continues for more than 2 hours or intermittent
            abdominal pain associated with fever, dehydration, or other signs of
            illness;
          </li>
          <li>
            Rash with fever or behavioral changes, until a physician has
            determined it is not a communicable disease;
          </li>
          <li>
            Purulent conjunctivitis (defined as pink or red conjunctiva with
            white or yellow eye discharge) until on antibiotics for 24 hours;
          </li>
          <li>Impetigo until 24 hours after treatment has been started;</li>
          <li>
            Strep throat (or other streptococcal infection) until 24 hours after
            treatment has been started;
          </li>
          <li>Head lice until after treatment and all nits are removed;</li>
          <li>Rubella, until 6 days after the rash appears;</li>
          <li>Scabies until 24 hours after treatment has been started;</li>
          <li>
            Chickenpox, until all lesions have dried or crusted (usually 6 days
            after onset of rash);
          </li>
          <li>
            Pertussis (Whooping cough) until 5 days of appropriate antibiotics;
          </li>
          <li>Mumps, until 5 days after onset of parotid gland swelling;</li>
          <li>Measles, until 4 days after onset of rash;</li>
          <li>
            Hepatitis A virus until 1 week after onset of illness or jaundice or
            as directed by the health department (if the child’s symptoms are
            mild);
          </li>
          <li>
            Tuberculosis, until the child’s medical provider or local health
            department states the child is on appropriate treatment and can
            return;
          </li>
          <li>
            Any child determined by the local health department to be
            contributing to the transmission of illness during an outbreak;
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Indemnity Consent
      </Typography>
      <Typography variant="body1">
        In exchange for your ability to utilize the on-demand care service, you,
        on behalf of yourself, your family and your minor child, hereby agree to
        release, hold harmless and indemnify Parent Day Out, its related
        companies, directors, officers, employees and agents from any and all
        claims, losses, damages or costs (including attorney’s fees) arising
        from your child’s registration and use of participating centers and
        their services. In addition, you acknowledge and agree that this
        agreement is not intended to be all inclusive and that your child’s
        registration and care is also subject to all policies and procedures of
        the facility providing the service.
      </Typography>
    </div>
  );
};

export default OndemandTermsPage;
