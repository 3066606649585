import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: (theme) => ({
    padding: "160px 16px 60px",

    [theme.mediaRequests.notMobile]: {
      display: "flex",
      margin: "56px auto 0",
      maxWidth: 1024,
      paddingTop: 30,
    },
  }),

  menuWrapper: {
    width: 300,

    "& $menu": {
      paddingTop: 40,
      position: "fixed",
    },
  },

  mobileMenuWrapper: {
    backgroundColor: "white",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, .08), 0 0 4px 0 rgba(0, 0, 0, .12)",
    left: 0,
    padding: "30px 16px 15px 16px",
    position: "fixed",
    top: 50,
    width: "100%",

    "& .trigger-btn": {
      justifyContent: "space-between",
    },
  },

  content: (theme) => ({
    [theme.mediaRequests.notMobile]: {
      flex: 1,
    },
  }),

  menu: {
    listStyle: "none",

    "& li": {
      fontSize: 18,
      lineHeight: "30px",
    },
  },

  legalPage: {
    "& ul, & ol": {
      paddingLeft: "1.3em",
    },

    "& ol": {
      counterReset: "item",

      "& > li": {
        counterIncrement: "item",
      },

      "& ol": {
        paddingLeft: "2.3em",
        listStyle: "none",

        "& > li": {
          position: "relative",
        },

        "& > li:before": {
          position: "absolute",
          transform: "translateX(calc(-100% - 3px))",
          content: "counters(item, '.') '. '",
        },
      },
    },
  },
});
