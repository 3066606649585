import React from "react";

import Typography from "@ui-kit/Typography";
import { useCss } from "../styles";
import { Spacer } from "@ui-kit/Spacer";

const OtherTermsPage: React.FC = () => {
  const css = useCss();

  return (
    <div css={css.legalPage}>
      <Typography variant="h3" gutterBottom align="center">
        MASTER PRODUCT SUITE AGREEMENT
      </Typography>
      <Typography paragraph align="justify">
        This Master Product Suite Agreement ("Agreement") between Client and
        Parent Day Out Inc., either directly or through its Subsidiaries or
        Affiliates ("Parent Day Out"), is entered into and effective upon the
        date Client accepts a Schedule referencing this Agreement (the
        "Effective Date"). "Client" is the legal entity or individual who
        accepts the Schedule. Parent Day Out and Client may be referred to each
        individually as a "Party" and collectively as the "Parties." A
        "Schedule" is any document, which is accepted by an authorized
        representative of each Party, that sets forth the Product Suite provided
        to Client, terms applicable to the specific Product Suite, and fees.
      </Typography>
      <Typography paragraph align="justify">
        In consideration of the mutual promises contained herein and other good
        and valuable consideration, the receipt and sufficiency of which are
        hereby acknowledged, the Parties agree as follows:
      </Typography>

      <Typography paragraph component="div">
        <ol>
          <li>
            <Typography paragraph component="div">
              <Typography paragraph align="justify">
                <Typography variant="inherit" bolded>
                  PARENT DAY OUT PRODUCT SUITE.
                </Typography>{" "}
                Parent Day Out will provide Client with the data, applications,
                services, and any other activity performed by Parent Day Out
                pursuant to Parent Day Out‘s Schedules and/or Order Forms
                (collectively, the "Product Suite"). At any time during the term
                of this Agreement, Parent Day Out and Client may enter into one
                or more of the following Schedules or Order Forms incorporating
                the specific Product Suite provided to Client and governed by
                this Agreement. Subsequently, the Parties may enter into
                Statements of Work under a specific Schedule for transactions
                related to that Schedule.
              </Typography>
              <ul>
                <li>Licensed Data Schedule</li>
                <li>Agency Services Schedule</li>
                <li>Data Processing Schedule</li>
                <li>Lead Generation Campaign Services Schedule</li>
                <li>Order Form(s)</li>
                <li>MultiChannel Services</li>
                <li>List Brokerage/List Management</li>
              </ul>
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                ENTIRE AGREEMENT AND ORDER OF PRECEDENCE.
              </Typography>{" "}
              This Agreement constitutes the entire agreement between the
              Parties with respect to the Client‘s use of Product Suite and
              merges all prior and contemporaneous communications. Each
              Schedule, Order Form, and Statement of Work will be subject to and
              incorporate the terms of this Agreement. The Parties agree that
              any term or condition stated in a Client purchase order or in any
              other Client order documentation is void. In the event of a
              conflict among the terms and conditions of the following
              documents, the descending order of precedence will be: Statement
              of Work, Schedule, Order Form, Agreement. Titles and headings of
              the sections of this Agreement are for description and convenience
              only and will not affect the construction of any provision of this
              Agreement.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div">
              <Typography paragraph align="justify">
                <Typography variant="inherit" bolded>
                  AFFILIATES AND SUBSIDIARIES.
                </Typography>{" "}
                Client‘s Affiliates may also purchase or license Product Suite
                under the same terms and conditions of this Agreement. Each
                Affiliate shall enter into a separate Schedule or Order Form and
                clearly indicate that the Schedule or Order Form is governed by
                this Agreement. "Affiliates" means any entity that owns or
                controls, is owned or controlled by, or is under common
                ownership or control with Client. "Ownership" means at least
                fifty-one percent (51%) of the equity of the applicable entity.
                "Control" means the right to exercise the voting rights
                associated with at least fifty-one percent (51%) of the voting
                equity of the applicable entity. Client shall remain fully
                liable for all Schedules, Order Forms, and Statements of Work
                executed pursuant to this Agreement. Affiliate will remain fully
                liable for each Schedule or Order Form it enters into.
              </Typography>
              <Typography align="justify">
                If an Affiliate enters into its own Schedule or Order Form, then
                Party, Parties, and Client will also include the Affiliate as to
                the Product Suite provided to such Affiliate. "Subsidiaries"
                means entities affiliate with Parent Day Out as may be
                identified by Parent Day Out from time to time.
              </Typography>
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                TERM.
              </Typography>{" "}
              The term of this Agreement will commence on the Effective Date and
              continue in effect until the termination or expiration of the last
              Schedule, unless terminated earlier in accordance with Section 5.
              If a term for a Schedule is not specified, then the term for such
              Schedule will be for one (1) year from the date of the last
              signature of the Schedule and will automatically renew in one-year
              increments, unless Parent Day Out gives Client written notice of
              nonrenewal at least thirty (30) days before the end of the
              relevant term. In addition to the rights granted in Section 5
              below, upon thirty (30) days‘ notice to Client, Parent Day Out may
              terminate this Agreement and or any Schedule at any time for any
              reason.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div">
              <Typography paragraph align="justify">
                <Typography bolded variant="inherit">
                  TERMINATION.
                </Typography>{" "}
                This Agreement and any Schedule may be terminated immediately
                where:
              </Typography>
              <ol>
                <li>
                  <Typography align="justify" variant="inherit">
                    Either Party commits a material breach of this Agreement,
                    Schedule, or Statement of Work and such breach remains
                    uncured after thirty (30) days of the non-breaching Party‘s
                    notification of such breach and the non-breaching Party
                    subsequently elects to terminate;
                  </Typography>
                  <Spacer />
                </li>
                <li>
                  <Typography paragraph align="justify" variant="inherit">
                    Either Party becomes subject to any involuntary
                    receivership, insolvency, bankruptcy, moratorium or similar
                    proceeding;
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify" variant="inherit">
                    At Parent Day Out‘s sole discretion, Client assigns, or
                    attempts to assign, this Agreement in breach of the
                    Assignment section of this Agreement;
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify" variant="inherit">
                    At Parent Day Out‘s sole discretion, Client or any of its
                    Affiliates have failed to pay Fees (defined below) or report
                    usage (as determined in a Schedule);
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify" variant="inherit">
                    At Parent Day Out‘s sole discretion, Client or any of its
                    Affiliates has breached Section 11 of this Agreement;
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify" variant="inherit">
                    At Parent Day Out‘s sole discretion, 50% or more of the
                    assets, equity securities, or voting interests of Client are
                    sold, assigned, or otherwise transferred in a single
                    transaction or a series of related transactions to any of
                    the Restricted Entities (defined below);
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify" variant="inherit">
                    At Parent Day Out‘s sole discretion, Client or any of its
                    Affiliates has materially breached any term or condition of
                    this Agreement, Schedule, or Statement of Work on three (3)
                    or more occasions, even if previous breaches were cured in
                    accordance with the provisions of this Section.
                  </Typography>
                </li>
              </ol>
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div">
              <Typography paragraph align="justify" bolded>
                EFFECT OF TERMINATION.
              </Typography>
              <ol>
                <li>
                  <Typography paragraph align="justify">
                    Termination of the Agreement will terminate all Schedules
                    and Statements of Work hereunder. Termination of a Schedule
                    or Statement of Work will not automatically terminate this
                    Agreement.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Upon termination or expiration of this Agreement or any
                    Schedule, all fees and royalties, including any and all
                    minimum fees, ("Fees") for the Product Suite will be due and
                    payable immediately. Termination of this Agreement for any
                    reason will not terminate, diminish, or otherwise affect
                    Client‘s obligation to pay all applicable Fees accrued under
                    a Schedule.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Upon termination or expiration of this Agreement or any
                    Schedule, Client shall immediately cease any and all use of
                    the Product Suite. Within thirty (30) days after the
                    expiration or termination of this Agreement or applicable
                    Schedule, Client shall certify in writing that Parent Day
                    Out‘s Confidential Information and Product Suite have been
                    completely, securely, and permanently destroyed, unless
                    legally prohibited.
                  </Typography>
                </li>
              </ol>
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div">
              <Typography paragraph align="justify" bolded>
                FEES AND PAYMENT.
              </Typography>
              <ol>
                <li>
                  <Typography paragraph align="justify">
                    Fees set forth in each applicable Schedule must be paid in
                    full by Client within thirty (30) days following the date of
                    Parent Day Out‘s invoice. Fees not paid within thirty (30)
                    days will accrue interest at the highest rate allowed by
                    applicable law. In the event Client fails to make timely
                    payments, Parent Day Out, in its sole discretion, may
                    suspend Client‘s access to the Product Suite and/or
                    accelerate the payment terms to require the amount of all
                    remaining Fees due immediately.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Where Client utilizes a third-party to manage its vendors
                    including but not limited to payment processing providers,
                    Client shall pay the cost for Parent Day Out‘s subscription
                    fee to such third-party.
                  </Typography>
                </li>
              </ol>
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                TAXES.
              </Typography>{" "}
              The Fees set forth in each Schedule are exclusive of any taxes.
              Client shall pay all taxes of any type, nature, or description
              associated with its purchases under a Schedule (including, but not
              limited to, sale, use, gross receipts, excise, import, export,
              income and employment taxes); provided, however, Client shall not
              pay for any income taxes imposed upon Parent Day Out by any taxing
              jurisdiction, arising by virtue of the performance of this
              Agreement.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div">
              <Typography paragraph align="justify" bolded>
                CONFIDENTIALITY.
              </Typography>
              <ol>
                <li>
                  <Typography paragraph align="justify">
                    Definition of Confidential Information. "Confidential
                    Information" means all nonpublic, confidential, or
                    proprietary information disclosed by a Party or its
                    Affiliates (the "Disclosing Party") to the other Party (the
                    "Receiving Party"), whether orally or in writing or via
                    electronic means, that is marked as "confidential" or that
                    should be reasonably understood to be confidential.
                    Confidential Information includes, without limitation, the
                    Product Suite, financial statements, business and marketing
                    plans, customer/client transactions customer/client lists,
                    pending or threatened litigation, prospective contractual
                    relations, collection, tabulation and analysis of data,
                    proprietary information, computer programming methods,
                    source code, object code, designs, specifications, plans,
                    drawings, programs, databases, intellectual property,
                    inventions (whether or not eligible for legal protection
                    under patent, trademark, or copyright laws), research and
                    development, and work in progress. The terms of this
                    Agreement will also be deemed Confidential Information.
                    Confidential Information does not include information that:
                    (i) is publicly available as of the Effective Date or
                    becomes publicly available thereafter through no fault of
                    the Receiving Party provided, however, that the Product
                    Suite will remain Confidential Information notwithstanding
                    any portion of the Product Suite being sourced from the
                    public domain; (ii) the Receiving Party rightfully possessed
                    the information before it was received from the Disclosing
                    Party; or (iii) is subsequently furnished to the Receiving
                    Party by a third-party without restrictions on disclosure.
                    Notwithstanding these provisions, Product Suite is always
                    Parent Day Out Confidential Information.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Use of Confidential Information. Each Party retains all
                    ownership rights in and to its Confidential Information. The
                    Receiving Party must use the Disclosing Party‘s Confidential
                    Information only to perform its obligations under this
                    Agreement, and for no other purpose. The Receiving Party
                    must keep confidential the Disclosing Party‘s Confidential
                    Information using the same degree of care it uses to keep
                    confidential its own Confidential Information, but in no
                    event less than reasonable care. The Receiving Party further
                    agrees not to disclose or permit any other person or entity
                    access to the Disclosing Party‘s Confidential Information,
                    except such disclosure or access of the Disclosing Party‘s
                    Confidential Information will be permitted to a
                    Representative of the Recipient that requires access, with
                    any such access limited only to the extent necessary in
                    order to perform his or her employment or services as they
                    relate to the Receiving Party‘s performance of its
                    obligations under this Agreement. The Receiving Party must
                    ensure that such Representatives sign confidentiality
                    agreements with the Receiving Party containing protections
                    not materially less protective of the Confidential
                    Information than those in this Agreement. The Receiving
                    Party must immediately notify the Disclosing Party in
                    writing of all circumstances which the Receiving Party
                    becomes aware of surrounding any possession, use, or
                    knowledge of Confidential Information by any person or
                    entity other than those authorized by this Agreement.
                    Subject to the limitations set forth in this Agreement, the
                    Receiving Party is fully responsible and liable for any
                    breach of this Section by any of its Representatives.
                    Permitted uses pursuant to this Section are subject to all
                    additional restrictions in the Agreement, Schedules, and
                    Statements of Work.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Required Disclosures; Notification. If the Receiving Party
                    is compelled by law, governmental regulation, court order,
                    or other legal process to disclose any Confidential
                    Information of the Disclosing Party, it may do so if: (i) it
                    provides the Disclosing Party with prompt prior notice of
                    such compelled disclosure (to the extent legally permitted);
                    and (ii) it provides the Disclosing Party with reasonable
                    assistance, at the Disclosing Party‘s reasonable expense, if
                    the Disclosing Party determines in its sole discretion to
                    contest the disclosure.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Return of Confidential Information. Upon the expiration or
                    termination of this Agreement or upon request of the
                    Disclosing Party during the term of this Agreement, the
                    Receiving Party shall return or irretrievably destroy all
                    Confidential Information of the Disclosing Party then in its
                    possession or control. In the case of destruction, the
                    Receiving Party must certify such destruction to the
                    Disclosing Party within thirty (30) days following the
                    expiration or termination of this Agreement or the
                    Disclosing Party‘s request
                  </Typography>
                </li>
              </ol>
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                PROPRIETARY RIGHTS.
              </Typography>{" "}
              As between the Parties, Parent Day Out is and will remain the sole
              and exclusive owner of all right, title, and interest, including
              all intellectual property rights, in and to the Product Suite, all
              information and data elements from one or more of Parent Day Out‘s
              proprietary databases, moral rights, rights of publicity, rights
              of privacy under applicable laws or regulations, any
              modifications, updates, improvements, upgrades, derivative works,
              Parent Day Out‘s Confidential Information and Feedback related
              thereto (collectively, "Proprietary Rights"). If Client provides
              suggestions, comments, ideas or recommendations, requests for
              enhancements, additional features or functionality, or other
              feedback to Parent Day Out (collectively "Feedback"), Client
              hereby assigns all right, title, and interest, including
              intellectual property rights, in and to the Feedback to Parent Day
              Out. Client‘s Confidential Information used in the Product Suite
              shall remain Client‘s property, and Parent Day Out shall have no
              ownership rights in Client‘s Confidential Information.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                LICENSE GRANT.
              </Typography>{" "}
              Except as expressly set forth in a Schedule or AR 101, Parent Day
              Out grants Client a limited, non-exclusive, non-transferable,
              non-sublicensable license to use the Product Suite for Client‘s
              internal and marketing purposes during the term of the applicable
              Schedule, not to exceed one (1) year. In the event that no license
              period to the Product Suite is set forth in a Schedule or AR 101,
              such license period will be deemed to be one (1) year commencing
              upon the effective date of the applicable Schedule or AR 101.
              Client agrees to only use the Product Suite in accordance with
              this Agreement and any applicable Schedule. All rights not
              expressly granted hereunder are reserved to Parent Day Out. Any
              use of the Product Suite not expressly authorized in this
              Agreement or any Schedule is strictly prohibited.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div">
              <Typography paragraph align="justify" bolded>
                CONDITIONS OF USE.
              </Typography>
              <ol>
                <li>
                  <Typography paragraph align="justify">
                    Except as expressly set forth in a Schedule, Client shall:
                    (a) strictly comply with this Agreement, all applicable
                    laws, and industry standards; (b) get and maintain all
                    necessary permissions and valid consents required to
                    lawfully transfer data to Parent Day Out and to enable such
                    data to be lawfully collected, processed, and shared by
                    Parent Day Out for the purposes of providing the Product
                    Suite or as otherwise directed by Client; (c) be responsible
                    for the accuracy, quality, and legality of any data and
                    information provided to Parent Day Out; (d) house the
                    Product Suite on the internet behind firewalls and prevent
                    unauthorized usage or copying of the Product Suite; (e)
                    protect the integrity of the Product Suite, control access
                    to the Product Suite, and reasonably ensure that the amount
                    of usage of the Product Suite is accurately recorded and at
                    a minimum, retain audit logs and other industry standard
                    measures; and (f) comply any applicable wireless suppression
                    lists, the Federal Trade Commission‘s Do-Not-Call lists, and
                    other opt-out lists before performing any direct marketing.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Except as expressly set forth in a Schedule, Client shall
                    not: (i) resell or redistribute the Product Suite on a
                    stand-alone basis or otherwise use the Product Suite in any
                    service or product not specifically authorized in this
                    Agreement or Schedule; (ii) disassemble, decompile, reverse
                    engineer, modify, or otherwise alter the Product Suite or
                    any part thereof; (iii) access any of the Product Suite not
                    intended for Client‘s own use; (iv) log on to a server or an
                    account that you are not authorized to access; (v) probe,
                    scan, or test the vulnerability of a system or network; (vi)
                    attempt to breach security or authentication measures
                    without proper authorization; (vii) interfere with services
                    to any user, host, or network, including, but not limited
                    to, submitting a virus to any Parent Day Out website,
                    overloading, flooding, spamming, mailbombing, or crashing;
                    (viii) use or attempt to sue any engine, software, tool,
                    agent, or other device or mechanism (including without
                    limitation, browsers, spiders, robots, avatars, or
                    intelligent agents) to navigate or search an Parent Day Out
                    website other than the search engine and search agents
                    available from Parent Day Out on one of Parent Day Out‘s
                    websites and other than generally available third-party web
                    browsers (e.g. Netscape Navigator, Microsoft Explorer); (ix)
                    use the Product Suite to store or transmit data that
                    infringe a third party‘s privacy right or intellectual
                    property right; (x) use the Product Suite in connection with
                    any mobile application (for example, for iOS and Android);
                    (xi) name or refer to Parent Day Out or Client‘s use of the
                    Product Suite in any of Client‘s advertisements or
                    promotional or marketing materials; (xii) use the Product
                    Suite to create, send, or display pornography, sexually
                    explicit content, or campaigns promoting or supporting
                    terrorism or violent extremism; (xiii) use data and
                    information derived from the Product Suite to help determine
                    an individual‘s eligibility for an extension of credit,
                    insurance, or health care; (xiv) use data and information
                    derived from the Product Suite to create or provide a
                    Consumer Report as defined in 15 U.S. Code § 1681a; (xv) use
                    data and information derived from the Product Suite to
                    evaluate an individual for employment or promotions,
                    reassignment, or retention as an employee; (xvi) use
                    purchased, rented, or third-party lists of email addresses
                    in connection with the Product Suite; (xvii) use or allow
                    third parties to use the Licensed Data for the purpose of
                    compiling, enhancing, verifying, supplementing, adding to or
                    deleting from any mailing list, geographic or trade
                    directories, business directories, classified directories,
                    classified advertising, or other compilation of information
                    which is sold, rented, published, furnished or in any manner
                    provided to or performed for a third party to include
                    digital display, advertising, or onboarding Licensed Data to
                    any data management platform or other platform or exchange;
                    (xvii) use data and information derived from the Product
                    Suite to help determine an individual‘s eligibility for a
                    license or other benefit granted by a governmental
                    authority; (xviii) co-brand or otherwise provide the Product
                    Suite on behalf of any third party; or (xix) use data
                    derived from the Product Suite for the purpose of soliciting
                    survivors of deceased persons. All licenses granted to
                    Client pursuant to this Agreement or any applicable Schedule
                    are expressly conditioned upon Client‘s (and all applicable
                    third parties) compliance with this Section.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Client acknowledges that the Product Suite may contain email
                    addresses. Client further acknowledges and agrees that, due
                    to Client‘s products or services being advertised in the
                    email, that Client is the "Sender‘ under the Controlling the
                    Assault of Non-Solicited Pornography and Marketing Act of
                    2003 ("CAN-SPAM Act") and is responsible for ensuring that
                    previous opt-outs are honored in any further email marketing
                    campaign by or on behalf of Client. Client waives any claim
                    against Parent Day Out arising out of or related to Parent
                    Day Out‘s transmission of an electronic mail message on
                    behalf of Client to any individual(s) that previously
                    opted-out of receiving such emails from Client. Client
                    agrees that it shall not: (i) send or deliver, directly or
                    indirectly, more than: (a) one (1) commercial electronic
                    mail message (as defined in the CAN-SPAM Act), in the
                    aggregate, to any single email address in any seven (7)
                    calendar day period; and (b) three (3) commercial electronic
                    mail messages, in the aggregate, to any single email address
                    in any calendar month; (ii) use, or permit any email address
                    to be used, in the transmission of any electronic mail
                    message in which the "Sender" (as defined in the CAN-SPAM
                    Act) is any person or entity other than the Client or, if
                    permitted by the applicable Schedule, Client‘s permitted
                    Affiliates; or (iii) use email addresses in a manner that
                    otherwise violates any applicable laws. If email addresses
                    are appended for Client, Client represents and warrants that
                    all individual records provided by Client represent
                    individuals with whom Client has an existing business
                    relationship prior to delivery of such records to Parent Day
                    Out. Client shall hold Parent Day Out harmless from any
                    loss, liability, claim, or cause of action arising out of or
                    related to: (a) Client‘s inappropriate use of email
                    addresses; and (b) Client‘s failure to provide an up-to-date
                    opt-out database prior to Parent Day Out‘s transmittal of
                    commercial electronic mail messages on Client‘s behalf. It
                    is Client‘s sole responsibility to determine the
                    applicability of any such laws, rules, and regulations
                    regarding email deployment.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Client acknowledges that the Product Suite may include
                    wireless telephone numbers. Client agrees and acknowledges
                    that: (i) Parent Day Out has not acquired consent in any
                    form from the owners of the wireless telephone numbers to be
                    contacted by Client in any manner; (ii) it is Client‘s sole
                    responsibility to ensure that any of the wireless telephone
                    numbers included in data derived from the Product Suite is
                    compliant with all applicable laws, rules, and regulations;
                    (iii) Client shall only use the data derived from the
                    Product Suite in strict compliance with all federal, state,
                    and local laws, rules, regulations, and ordinances,
                    including but not limited to those concerning telephone
                    solicitation, privacy, and direct marketing; (iv) it is
                    Client‘s sole responsibility to determine the applicability
                    of all laws, rules, regulations, and ordinances to the data
                    derived from the Product Suite; and (v) Client shall hold
                    Parent Day Out harmless from any loss, liability, claim, or
                    cause of action arising out of or related to Client‘s
                    inappropriate use of wireless telephone numbers.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    If Client utilizes, and Parent Day Out consents to the use
                    of, the services of a third-party processor or data storage
                    provider ("Third Party") to process or store any Product
                    Suite, Client shall ensure that (i) such Third Party adheres
                    to the prevailing industry standards with respect to data
                    security; (ii) usage of the Product Suite by a Third Party
                    is limited to storing the Product Suite on behalf of Client;
                    (iii) the Product Suite are not stored internationally; (iv)
                    such Third Party signs Parent Day Out‘s standard Third Party
                    agreement; and (v) Client is fully liable for any Third
                    Party‘s access to the Product Suite.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Client will not use on behalf of or otherwise make any
                    portions of the Product Suite available to the Restricted
                    Entities (defined below) without Parent Day Out‘s prior
                    written approval.
                  </Typography>
                </li>
              </ol>
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                MAILER-SPECIFIC TERMS.
              </Typography>{" "}
              This Section 13 only applies if Client is a mailer or is acting as
              an agent for a mailer. Client acknowledges and agrees that all
              rental names furnished by Parent Day Out are provided for the sole
              and exclusive one-time use by Client in connection with the mail
              piece materials approved by Parent Day Out and are not for use by
              any third-party. Client agrees to indemnify, defend, and hold
              harmless Parent Day Out and the owner(s) of any lists and/or
              rentals names furnished to Parent Day Out from any claim or cause
              of action arising out of or relating to Client‘s use of the lists
              and/or rental names in violation of this Agreement. Client
              represents and warrants that its use of any rental names and/or
              lists will in all cases comply with all laws and regulations and
              that all mail or other marketing programs using any rental names
              and/or lists will conform to the Direct Marketing Association‘s
              Ethical Guidelines for direct marketing practices. The rental
              names and lists are provided as-is. Client agrees that neither
              Parent Day Out nor any owner(s) of any lists and/or rentals names
              furnished to Parent Day Out will be liable for any loss or injury
              arising out procuring, compiling, collecting, interpreting,
              reporting, communicating, or delivering the rental names and/or
              lists. Client will not use any trade names, trademarks, service
              marks, or copyrighted materials of Parent Day Out or any owner(s)
              of any lists and/or rentals names furnished to Parent Day Out in
              listings or advertising in any manner without the prior written
              approval of Parent Day Out. Client agrees that the owner(s) of any
              lists and/or rentals names furnished to Parent Day Out may, in its
              own name, enforce this Agreement against Client and the owner(s)
              of any lists and/or rentals names furnished to Parent Day Out are
              third-party beneficiaries of this Agreement for such purposes;
              provided, however, Client agrees that it will look only to Parent
              Day Out, and not the owner(s) of any lists and/or rentals names
              furnished to Parent Day Out, for performance of obligations under
              this Agreement.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                USER ACCOUNT TERMS.
              </Typography>{" "}
              If Client and Client‘s users are given user accounts to access the
              Product Suite, Client and Client‘s users must: (i) be at least
              eighteen (18) years of age; (ii) create an account and a password
              on one of Parent Day Out‘s websites; (iii) agree to the terms and
              conditions of this Agreement; and (iv) provide true, complete,
              accurate, and current contact and billing information. If Client
              is an individual and assigned an account by his or her employer,
              educational institution, or other organization, additional terms
              may apply and govern the use of the Product Suite. Client and each
              of Client‘s users may not share accounts or passwords with any
              other person or entity. Should Client or Client‘s users believe
              their account has been compromised, please contact Parent Day Out
              as soon as possible. Parent Day Out may terminate Client‘s user
              accounts at any time and for any reason by providing Client prior
              written notice. Parent Day Out may suspend Client‘s user accounts
              at any time, with or without cause.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                FILE MANAGEMENT LIBRARY.
              </Typography>{" "}
              Certain Product Suite, including Salesgenie, may include a file
              management library allowing Client to store and access certain
              marketing creative (e.g. Client‘s logos, email creative, images)
              and other documents (collectively, "Marketing Content") for
              Client‘s internal use. Any such Marketing Content and art will
              remain Client‘s property; however, Client gives Parent Day Out
              permission to host, store, and to allow access to Client‘s users.
              Client is solely responsible for ensuring that Client has all
              necessary rights and licenses to the Marketing Content and to use
              that Marketing Content in connection with the Product Suite.
              Parent Day Out is not responsible for actions Client takes with
              respect to Client‘s Marketing Content. Client agrees to not upload
              Marketing Content that, or otherwise use the Product Suite, to:
              (i) violate the intellectual property rights of any third party;
              (ii) engage in or promote illegal activity; or (iii) distribute
              viruses, worms, or other malware or malicious software. Parent Day
              Out reserves the right to delete or disable content alleged to
              violate the foregoing; however, Parent Day Out has no obligation
              to monitor or review Client‘s Marketing Content. Client
              acknowledges that any file management library is made available
              for Client‘s convenience and is not intended to be used as a data
              backup service or in connection with disaster recovery. Client is
              responsible for maintaining independent copies of all Marketing
              Content, including backup copies. Marketing Content is subject to
              deletion upon termination.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                PRODUCT SUITE SEEDING.
              </Typography>{" "}
              Parent Day Out has a process to randomly monitor Client‘s use of
              the Product Suite, through seeding or other means, to ensure
              Client‘s use is in accordance with this Agreement and the
              Schedules.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                PRODUCT SUITE AUDIT.
              </Typography>{" "}
              Parent Day Out may, during the term of this Agreement and for a
              period of two (2) years following termination or expiration of
              this Agreement, audit any and all of Client‘s books, records, and
              systems that relate to, load, store, process, or read the Product
              Suite. Audits will be: (i) performed by Parent Day Out or its
              agent; (ii) conducted only during normal business hours; (iii)
              preceded by at least thirty (30) days' written notice; and (iv)
              limited to once in any twelve (12) month period. Client agrees to
              fully cooperate and provide Parent Day Out with any access to the
              books, records, and systems as well as any knowledgeable employees
              of Client as necessary to audit use of the Product Suite. Parent
              Day Out will be solely responsible for the costs and expenses of
              such audit; provided, however, Client will pay the costs and
              expenses of such audit and any applicable charges if the audit
              reveals that Client has not complied with this Agreement.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div">
              <Typography paragraph align="justify" bolded>
                DATA SECURITY AND PRIVACY.
              </Typography>
              <ol>
                <li>
                  <Typography paragraph align="justify">
                    Each Party shall maintain and ensure all third parties
                    maintain reasonable and appropriate administrative,
                    physical, and technical safeguards to protect the security,
                    confidentiality, and integrity of the other Party‘s
                    Confidential Information. Those safeguards include measures
                    to protect Confidential Information from loss, misuse,
                    unauthorized disclosure, unauthorized access, alteration,
                    and destruction. Client shall maintain and enforce those
                    safeguards to prevent unauthorized or fraudulent use or
                    access of the Product Suite.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Parent Day Out and Client may enter into a data processing
                    addendum ("DPA"), which will be incorporated into this
                    Agreement by reference and apply to Personal Data as defined
                    in the DPA. If any Personal Data is transferred from the
                    European Union, the United Kingdom, or Switzerland by Client
                    to Parent Day Out, the EU-U.S. and Swiss-U.S. Privacy Shield
                    Frameworks and/or the Standard Contractual Clauses will
                    apply. For the purposes of this Agreement, Client and its
                    Affiliates are the data exporters.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Each party commits to report any Data Security Breach to the
                    other party within twenty-four (24) hours of discovery of
                    the Data Security Breach. "Data Security Breach" means any
                    unauthorized access, use, disclosure, modification, or
                    destruction of the other Party‘s Confidential Information.
                    In the event of a Data Security Breach, each Party will use
                    commercially reasonable efforts to prevent the recurrence of
                    a similar Data Security Breach.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph align="justify">
                    Parent Day Out will require its data onboarding partners to
                    have a posted privacy statement that reflects its current
                    data use and privacy practices and complies with all
                    applicable laws and regulations. Parent Day Out will require
                    its data onboarding partners to provide disclosures in its
                    privacy policy of the nature and operation of the data
                    cookie network and give clear instructions enabling users to
                    opt-out of data cookies on the user‘s browser and prevent
                    the placement of future data cookies. Parent Day Out will
                    require its data onboarding partners to permit web users to
                    opt-out of receiving cookies.
                  </Typography>
                </li>
              </ol>
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                REPRESENTATIONS, WARRANTIES, AND DISCLAIMERS.
              </Typography>{" "}
              Each Party represents and warrants that: (i) it has the right and
              power to enter into this Agreement; (ii) an authorized
              representative has read and accepted this Agreement; (iii) it will
              comply with all applicable laws and regulations pertaining to this
              Agreement. Client, including Client‘s users, represents and
              warrants that Client either owns or has the authority, license, or
              permission to use all material, content, data, and information
              provided to Parent Day Out and/or used within the Product Suite
              Parent Day Out warrants that: (a) it will perform the Product
              Suite in a professional and workmanlike manner; (b) the Product
              Suite will materially perform in accordance with this Agreement;
              (c) the Product Suite has not undergone any specific data
              processing services, including but not limited to, Do-Not-Call or
              wireless number suppression; and (d) the Product Suite may not
              meet applicable legal requirements related to the use of marketing
              information, including but not limited to, laws applicable to
              commercial email or other electronic messages, telemarketing laws,
              privacy, and wireless suppression or wireless domain lists. All
              orders will be free of defects in workmanship and if the order
              does not match the generally accepted trade standards, Parent Day
              Out will, at its discretion, either reperform the services or
              issue Client a refund. Claims for defects, damages, or shortages
              must be made by Client in writing no later than ten (10) days
              after delivery. EXCEPT FOR THE WARRANTIES SET FORTH IN THIS
              SECTION, THE SERVICES, DATA, AND PRODUCT SUITE ARE PROVIDED ON AN
              "AS IS" BASIS AND PARENT DAY OUT HEREBY DISCLAIMS ALL OTHER
              REPRESENTATIONS AND WARRANTIES TO CLIENT OR TO ANY THIRD PARTY,
              EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES
              OF MERCHANTABILITY, SUITABILITY, QUALITY, FITNESS FOR A PARTICULAR
              PURPOSE, NON-INFRINGEMENT, OR RESULTS TO BE DERIVED FROM THE USE
              OF THE SERVICES, DATA, OR PRODUCT SUITE. WITHOUT LIMITING THE
              GENERALITY OF THE IMMEDIATELY PRECEDING SENTENCE, NEITHER PARENT
              DAY OUT NOR ANY OF ITS THIRD-PARTY SUPPLIERS OR SERVICE PROVIDERS
              MAKE ANY REPRESENTATIONS OR WARRANTIES REGARDING THE ACCURACY,
              COMPREHENSIVENESS OR COMPLETENESS OF THE SERVICES, DATA, OR
              PRODUCT SUITE NOR THAT THEY WILL BE UNINTERRUPTED. EXCEPT WHERE
              OTHERWISE PROHIBITED OR LIMITED BY LAW, THIS PROVISION REPRESENT‘S
              CLIENT‘S SOLE REMEDY AS REGARDS A WARRANTY CLAIM.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                INDEMNIFICATION.
              </Typography>{" "}
              Each Party shall indemnify and defend the other Party against any
              third-party claims arising from the indemnifying Party‘s gross
              negligence or willful misconduct. Client shall indemnify and
              defend Parent Day Out against any third-party claims arising from
              Client or its Affiliates‘: (a) use of the Product Suite in
              violation of the terms of this Agreement or Schedule; and (b) data
              provided to Parent Day Out that infringe a third party‘s
              intellectual property right. Parent Day Out shall indemnify and
              defend Client against any third-party claims arising from Parent
              Day Out‘s Product Suite infringing a third party‘s intellectual
              property right. If Parent Day Out receives information regarding
              an infringement claim related to the Product Suite, Parent Day Out
              may in its sole discretion: (i) modify the Product Suite so that
              the Product Suite is no longer infringing; (ii) obtain a license
              for Client‘s continued use of the Product Suite; or (iii)
              terminate the Product Suite and refund Client for any prepaid fees
              for unused Product Suite. Parent Day Out‘s indemnity for
              intellectual property infringement stated above only applies if:
              (1) the allegation states with specificity that the Product Suite
              is the basis for the claim for indemnification; (2) the allegation
              arises from the use of the Product Suite with software, hardware,
              data, or processes provided directly by Parent Day Out; and (3)
              Client uses the Product Suite in accordance with this Agreement or
              Schedule. The indemnified Party shall give the indemnifying Party
              advance written notice of any claims for indemnification. The
              indemnified Party agrees to relinquish control of defending any
              such claim to the indemnifying Party, including the right to
              settle; provided, however, the indemnifying Party may not settle
              any such claim without the indemnified Party‘s prior written
              consent if such settlement would be adverse to the interests of
              the indemnified Party. This section states the entire liability of
              each Party with respect to third-party claims.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                LIMITATIONS ON LIABILITY.
              </Typography>{" "}
              IN NO EVENT WILL EITHER PARTY BE LIABLE TO THE OTHER PARTY FOR ANY
              INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR
              PUNITIVE DAMAGES OR "COSTS OF COVER" ARISING OUT OF OR RELATING TO
              THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES ARISING
              OUT OF ANY MISTAKES, OMISSIONS, INTERRUPTIONS, DELAYS, ERRORS,
              DEFECTS, LOSS OF DATA, LOSS OF PROFITS, LOSS OF BUSINESS, OR
              ANTICIPATORY PROFITS, REGARDLESS OF WHETHER THE POSSIBILITY OF
              SUCH DAMAGES WAS MADE KNOWN OR WAS FORESEEABLE. THE MAXIMUM
              AGGREGATE LIABILITY OF PARENT DAY OUT AND ITS AFFILIATES TO CLIENT
              AND ITS AFFILIATES, UNDER ANY THEORY OF TORT, CONTRACT, STRICT
              LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY, ARISING OUT OF OR
              RELATING TO THIS AGREEMENT WILL NOT EXCEED THE TOTAL FEES PAID BY
              CLIENT TO PARENT DAY OUT FOR THE SERVICES, DATA, OR PRODUCT SUITE
              FROM WHICH THE CLAIM AROSE DURING THE TWELVE (12) MONTHS PRECEDING
              THE CLAIM.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                FORCE MAJEURE.
              </Typography>{" "}
              Neither Party will be responsible for any failure to perform
              (except for Client‘s payment obligations) due to unforeseen
              circumstances or to causes beyond its control, including but not
              limited to acts of God, war, terrorism, riot, embargoes, acts of
              civil or military authorities, earthquakes, fire, floods,
              accidents, strikes, shortages of transportation facilities, fuel,
              energy, labor or materials, denial of service attacks, or failures
              of telecommunications or electrical power supplies ("Force Majeure
              Event"). Both Parties shall use all reasonable efforts to overcome
              or work around the Force Majeure Event as soon as reasonably
              practicable.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                RESTRICTED ENTITIES.
              </Typography>{" "}
              "Restricted Entities" include DiscoverOrg; Experian PLC; Equifax
              Inc.; Epsilon Data Management LLC; Insideview; KBM Group; Merkle
              Group Inc.; MeritDirect: Acxiom; DatabaseUSA; Dun and Bradstreet,
              Inc.; Allant Group, LLC; HERE Technologies; Factual, Localeze,
              Locationary, Inc. Neustar Inc., Safegraph; TomTom N.V.,
              TransUnion; Thomson-West, Knowledgebase, Database LLC, Accudata
              Integrated Marketing, Inc.; Compass, Zoominfo or any subsidiaries
              or affiliates of these Restricted Entities. Parent Day Out may
              revise the list of Restricted Entities upon written notice to
              Client.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                ASSIGNMENT.
              </Typography>{" "}
              Neither Party may assign this Agreement or any of its rights or
              obligations under this Agreement without the other Party‘s prior
              written consent; provided, however, either Party may assign this
              Agreement in its entirety, without the other Party‘s prior written
              consent, to its Affiliate or in connection with a merger,
              acquisition, corporate reorganization, or the sale of all or
              substantially all of its assets related to this Agreement. Client
              may not assign this Agreement in whole or in part if the merger,
              acquisition, corporate reorganization, or the sale of all or any
              of the assets involves a Restricted Entity. Any attempted
              assignment in violation of this Section will be deemed null and
              void. This Agreement will be binding upon and will inure to the
              benefit of the Parties hereto and their respective heirs,
              representatives, successors, and permitted assignees.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                MODIFICATIONS, SCHEDULES, STATEMENTS OF WORK.
              </Typography>{" "}
              No modification of this Agreement, Schedule, or Statement of Work
              will be binding upon Client and Parent Day Out unless made in
              writing and signed by duly authorized officers of both Parties.
              However, in the event Parent Day Out ceases to provide a certain
              Product Suite, Parent Day Out may, in Parent Day Out‘s sole
              discretion, unilaterally replace that certain Product Suite with a
              comparable Product Suite as long as Parent Day Out provides Client
              with a three (3) months‘ notice about the replacement.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                REMEDIES AND WAIVERS.
              </Typography>{" "}
              All remedies hereunder are cumulative and the exercise by any
              Party of any rights hereunder will not preclude such Party from
              pursuing any additional remedies available to it under this
              Agreement, or any other legal or equitable rights afforded such
              Party. The failure of either Party to require the performance of
              any term or condition of this Agreement will not prevent any
              subsequent enforcement of this term or condition, nor will it be
              deemed a waiver of any subsequent breach.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                GOVERNING LAW, CONSENT TO JURISDICTION, AND WAIVER OF JURY
                TRIAL.
              </Typography>{" "}
              This Agreement is governed by the laws of the State of Nebraska,
              without regard to the conflicts of law principles. The Parties
              irrevocably submit to the exclusive jurisdiction of any United
              States federal or Nebraska state court located in Omaha, Nebraska
              with respect to any matter arising out of or relating to this
              Agreement. THE PARTIES AND ANY OF THEIR AFFILIATES WAIVE THE RIGHT
              TO A TRIAL BY JURY ON ANY CLAIM RELATING TO OR ARISING OUT OF THIS
              AGREEMENT.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                STATUTORY EXCEPTION.
              </Typography>{" "}
              If Client is a qualified public educational or government
              institution, any part of this Agreement which may be invalid or
              unenforceable against Client because of applicable law will be
              deemed invalid or unenforceable and will be construed in a manner
              consistent with applicable law. If required by applicable law,
              this Agreement will be governed by the applicable law to enter
              into an agreement with Client.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                SEVERABILITY.
              </Typography>{" "}
              A decision by any court of competent jurisdiction invalidating or
              holding unenforceable any part of this Agreement will not affect
              the validity and enforceability of any other part of this
              Agreement.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                RELATIONSHIP OF THE PARTIES.
              </Typography>{" "}
              Each Party will perform its obligations under this Agreement as an
              independent contractor, not as the other Party’s employee,
              partner, or agent.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                THIRD PARTY BENEFICIARIES.
              </Typography>{" "}
              Except for Section 13 if applicable, there are no third-party
              beneficiaries to this Agreement.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                NOTICES.
              </Typography>{" "}
              Any notice required will be given in email or writing. If the
              notice is delivered by email, the notice will be deemed
              effectively given when the other Party acknowledges having
              received that email. If the notice is delivered in writing, the
              notice will be deemed effectively given upon personal delivery,
              deposit in the U.S. post office as certified or registered mail,
              or deposited with a private next day delivery service. All
              correspondence to Client required by this Agreement will be
              addressed to the contact information within the Schedule, Order
              Form, or Statement of Work.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                SURVIVING PROVISIONS.
              </Typography>{" "}
              The following provisions will survive the termination or
              expiration of this Agreement for any reason: Sections 2 – 10, 12,
              15 – 21, 23, 26, 27, 29 – 34, as well as any other provisions
              which, by their nature, are intended to survive termination or
              expiration of this Agreement.
            </Typography>
          </li>
          <li>
            <Typography paragraph component="div" align="justify">
              <Typography variant="inherit" bolded>
                COUNTERPARTS.
              </Typography>{" "}
              This Agreement may be executed in two or more counterparts, each
              of which will be deemed to be an original, and all of which will
              together constitute one and the same agreement and will become
              effective when signed by the Parties and delivered to the other
              Party in person or by other reliable electronic means. The Parties
              agree that this Agreement, once validly executed, may be stored by
              electronic means and that either an original or an electronically
              stored copy of this Agreement can be used for all purposes,
              including in any proceeding to enforce the rights and obligations
              of the Parties to this Agreement.
            </Typography>
          </li>
        </ol>
      </Typography>
    </div>
  );
};

export default OtherTermsPage;
