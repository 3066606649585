import React from "react";

import Typography from "@ui-kit/Typography";
import { useCss } from "../styles";

const IndexTermsPage: React.FunctionComponent = () => {
  const css = useCss();

  return (
    <div css={css.legalPage}>
      <Typography variant="h3" gutterBottom align="center">
        Terms of use
      </Typography>
      <Typography variant="body1" paragraph align="justify">
        Thank you for using the Parent Day Out Inc. website (accessible
        at:”www.parentdayout.com” hereinafter, the “website”). This page states
        the terms and conditions under which you may use the website and any
        materials, online communications and other information that is or
        becomes available on the website. By accessing the website you accept
        and agree to be bound, without limitation or qualification by these
        terms. If you do not accept any of the terms stated here, do not use the
        website.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Contractual Relationship
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing the website you accept and agree to be bound, without
        limitation or qualification by this policy. If you do not accept any of
        the terms stated here, do not use the website. If you have questions or
        concerns about this policy, you should contact us by emailing{" "}
        <a href="mailto:support@parentdayout.com">support@parentdayout.com</a>.
      </Typography>
      <Typography variant="body1" paragraph>
        PLEASE READ THESE TERMS CAREFULLY, AS THEY CONSTITUTE A LEGAL AGREEMENT
        BETWEEN YOU AND PARENT DAY OUT. In these Terms, the words "including"
        and "include" mean "including, but not limited to."
      </Typography>
      <Typography variant="body1" paragraph>
        By accessing or using the Services, you confirm your agreement to be
        bound by these Terms. If you do not agree to these Terms, you may not
        access or use the Services. These Terms expressly supersede prior
        agreements or arrangements with you. Parent Day Out may immediately
        terminate these Terms or any Services with respect to you, or generally
        cease offering or deny access to the Services or any portion thereof, at
        any time for any reason.
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out may amend the Terms from time to time. Amendments will be
        effective upon Parent Day Out's posting of such updated Terms at this
        location or in the amended policies or supplemental terms on the
        applicable Service(s). Your continued access or use of the Services
        after such posting confirms your consent to be bound by the Terms, as
        amended
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Agreement Between You and Parent Day Out
      </Typography>
      <Typography variant="body1" paragraph>
        The website is owned and operated by Parent Day Out Inc (hereinafter
        “Parent Day Out”) and is offered to you conditioned on your acceptance
        without modification of the terms, conditions, disclaimers and notices
        contained herein. Use of the content, services, and/or products
        presented in any and all areas of this site constitutes your agreement
        that you will not use the website for any unlawful purpose and that you
        will abide by the following terms and conditions and those posted in
        specific areas of the site. This agreement between you and Parent Day
        Out (hereinafter “Agreement”) is effective until terminated by Parent
        Day Out, and may be terminated or changed by Parent Day Out at any time
        without notice.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Your Obligations
      </Typography>
      <Typography variant="body1" paragraph>
        In connection with your use of the website, you shall abide by all
        applicable federal, state or local laws including those pertaining to
        such areas as libel, slander, defamation, trade libel, product
        disparagement, harassment, invasion of privacy, tort, obscenity,
        indecency and copyright or trademark infringement. You are responsible
        for the content of any information that you put on the website. You
        agree that no materials of any kind submitted to the website by you will
        violate or infringe upon the rights of any third party, including
        copyright, trademark, privacy or other personal or proprietary rights;
        or contain libelous, defamatory or otherwise unlawful material or be
        harmful to minors in any way. Parent Day Out has no obligation to, and
        does not in the normal course, monitor or control any information that
        is or becomes available on the Parent Day Out website. Parent Day Out
        reserves the right to refuse to post or to remove any information that
        is, in Parent Day Out’ sole discretion, unacceptable, undesirable or in
        violation of these rules. However, Parent Day Out has no obligation to
        exercise such reservation of rights by Parent Day Out. By posting
        content to any part of the website, you automatically grant, and you
        represent and warrant that you have the right to grant, to Parent Day
        Out an irrevocable, perpetual, non-exclusive, transferable, fully paid,
        royalty-free, worldwide license (with the right to sublicense) to use,
        copy, perform, display, reformat, translate, excerpt (in whole or in
        part) and distribute such information and content and to prepare
        derivative works of, or incorporate into other works, such information
        and content, and to grant and authorize sublicenses of the foregoing.
        Parent Day Out authorizes you to view and download the materials on the
        website solely for your personal, non-commercial use. You may not sell
        or modify any information on the website or reproduce, display, publicly
        perform, distribute or otherwise use the materials in any way for any
        public or commercial purpose without the advance written permission of
        Parent Day Out.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        General Disclaimer
      </Typography>
      <Typography variant="body1" paragraph>
        The website is designed to provide users with information to help choose
        schools for their children and understand the school system in their
        community. Unless otherwise noted, information included on the website
        is provided free of charge as a user convenience and is to be used for
        informational purposes only. Some information contained on the website
        may represent opinion or judgment or contain inadvertent technical
        oversights, factual inaccuracies, or typographical errors. Parent Day
        Out does not guarantee the accuracy or completeness of any information
        on the website. As such, Parent Day Out will not be responsible for any
        errors, inaccuracies, omissions or deficiencies in the information
        provided on the website. This information is provided “as is,” with no
        guarantees of completeness, non-infringement, accuracy or timeliness,
        and without warranties of any kind, express or implied. You therefore
        assume sole responsibility for all risks associated with the use of this
        information and further accept that Parent Day Out is in no way
        responsible for any consequences whatsoever to anyone arising from your
        use or interpretation of any information contained within or linked from
        or to the website. Before making decisions based on the information
        contained here, we strongly recommend that you visit the schools.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Use of the Website Information at Your Own Risk
      </Typography>
      <Typography variant="body1" paragraph>
        The website may contain information or materials from various
        information sources such as school administrators and Parent Day Out
        does not represent or endorse the accuracy or reliability of any
        information or materials provided by these sources or any information,
        data, or analysis derived from these sources. Reliance upon any such{" "}
        information or material shall also be at your own risk. Neither Parent
        Day Out nor its affiliates, partners, officers, directors, employees,
        subsidiaries or agents shall be liable to you or anyone else for any
        inaccuracy, error, omission, interruption, timeliness, completeness,
        deletion, defect, failure of performance, computer virus, communication
        line failure, alteration of, or use of any content herein, regardless of
        cause, or for any damages resulting therefrom.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Posting Your Content Online
      </Typography>
      <Typography variant="body1" paragraph>
        When posting content online to Parent Day Out, you agree to the
        following terms and conditions. You give us a fully paid, royalty-free,
        irrevocable, perpetual, transferable, worldwide right and license (with
        the right to sublicense) to use, copy, modify, publicly perform,
        publicly display, reformat, translate, excerpt (in whole or in part) and
        distribute submitted content and to prepare derivative works of, or
        incorporate into other works, such content, in any manner and medium.
        You further agree that (1) you will not submit any content that is
        protected by copyright or otherwise proprietary information of a third
        party, unless you have the right to do so; (2) any information you
        submit may be modified for brevity or for any other reason prior to
        posting at the sole discretion of Parent Day Out; (3) if you submit an
        email address, it may be used by Parent Day Out and in co-marketing or
        co-branded communications Parent Day Out may send you; (4) the Parent
        Day Out website and its contents are provided “as is” without any
        warranty, to the maximum extent permitted by law; (5) any information
        you submit will be true and correct; and (6) you will indemnify and hold
        Parent Day Out harmless to the extent that Parent Day Out is subject to
        claims arising from any information that you submit.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Mapbox data
      </Typography>
      <Typography variant="body1" paragraph>
        Users are bound by the Mapbox Terms of Service (including Mapbox Privacy
        Policy)
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Who do I contact for help?
      </Typography>
      <Typography variant="body1" paragraph>
        For additional help, please contact{" "}
        <a href="mailto:support@parentdayout.com">support@parentdayout.com</a>
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Copyright Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out respects the intellectual property rights of others. If
        you believe your work has been copied in a way that constitutes
        copyright infringement or are aware of any infringing material on the
        website, please contact us at{" "}
        <a href="mailto:support@parentdayout.com">support@parentdayout.com</a>{" "}
        and provide us with the following information: an electronic or physical
        signature of the person authorized to act on behalf of the owner of the
        copyright interest; a screenshot of the copyrighted work that you claim
        has been infringed; a description of where the material that you claim
        is infringing is located on the website; your address, telephone number,
        and email address; a written statement by you that you have a good faith
        belief that the disputed use is not authorized by the copyright owner,
        its agent, or the law; a statement by you, made under penalty of
        perjury, that the above information in your notice is accurate and that
        you are the copyright owner or authorized to act on the copyright
        owner’s behalf.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Limitation on Liability
      </Typography>
      <Typography variant="body1" paragraph>
        Under no circumstances shall Parent Day Out or any of its affiliates,
        partners, officers, directors, employees, subsidiaries or agents be held
        liable for any damages, whether direct, incidental, indirect, special,
        or consequential damages, including, without limitation, lost use, data,
        revenues, time, money, profits or goodwill arising from or in connection
        with the use, reliance on, or performance of the information on the
        website, even when Parent Day Out has been advised of the possibility of
        such damages. Parent Day Out shall not be liable for damages or injury
        caused in whole or in part, whether foreseeable or unforeseeable, and
        whether based in tort (including defamation), contract, strict liability
        or otherwise in producing and publishing this website or any information
        contained in this site or linked by or to this site. If your are
        dissatisfied with any of the website’s material or services, or with any
        of the website’s terms and conditions, your sole and exclusive remedy is
        to discontinue using the website.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Indemnity
      </Typography>
      <Typography variant="body1" paragraph>
        As a condition of use of the website, you agree to indemnify Parent Day
        Out and its affiliates, partners, officers, directors, employees,
        subsidiaries and agents from and against any and all liabilities,
        expenses (including attorneys’ fees) and damages arising out of any and
        all claims resulting from your use of the website and the materials
        (including software) thereon, including without limitation any claims
        alleging facts that if true would constitute a breach by you of these
        Terms of Use. This indemnity shall include, without limitation, any
        claim of inaccuracy or defamation based on materials that you submit for
        use on the site.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Third-Party Sites
      </Typography>
      <Typography variant="body1" paragraph>
        The website may contain links to third-party websites. If included,
        Parent Day Out provides these links solely to assist users in locating
        other Internet resources that may be of interest to them and you assume
        sole responsibility and risk for your use of links to third-party
        websites. Parent Day Out does not operate or control in any respect any
        information, content, products or services on such third-party websites.
        Parent Day Out does not represent or endorse the accuracy or reliability
        of any of the information, content or advertisements contained on,
        distributed through, or linked, downloaded or accessed from any of the
        services contained on these third-party websites. No reference or link
        to a third party or a third-party site shall constitute an endorsement
        of such third party or such third-party site.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Editorial and Advertising Policy
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out has a policy of separation between editorial and
        advertising content. We do not allow advertisers to influence our
        editorial content. We believe you should always know the source of the
        information you’re reading, and we will not accept advertisements that
        we think are misleading. All advertising is either clearly separated
        from editorial content or clearly labeled “advertisement” or “from our
        sponsors.” We reserve the right to reject an ad based on its
        appropriateness to our audience.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Specific Disclaimer of Warranties
      </Typography>
      <Typography variant="body1" paragraph>
        ALL MATERIALS AND SERVICES IN THIS WEBSITE, INCLUDING THOSE PROVIDED BY
        LINKS TO THIRD-PARTY WEBSITES ARE PROVIDED “AS IS” WITHOUT WARRANTY OF
        ANY KIND. THE CONTENT PUBLISHED ON THIS WEBSITE MAY INCLUDE INACCURACIES
        OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY MADE TO THE
        INFORMATION HEREIN. PARENT DAY OUT MAKES NO REPRESENTATIONS AND, TO THE
        FULLEST EXTENT ALLOWED BY LAW, DISCLAIMS ALL WARRANTIES, EXPRESS OR
        IMPLIED, INCLUDING, BUT NOT LIMITED TO WARRANTIES OF NON-INFRINGEMENT,
        MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE REGARDING THE
        SUITABILITY OF THE INFORMATION; THE ACCURACY, RELIABILITY, COMPLETENESS
        OR TIMELINESS OF THE CONTENT, SERVICES, PRODUCTS, TEXT, GRAPHICS, LINKS,
        OR OTHER ITEMS CONTAINED WITHIN THE WEBSITE, OR THE RESULTS OBTAINED
        FROM ACCESSING AND USING THIS SITE AND/OR THE CONTENT CONTAINED HEREIN.
        PARENT DAY OUT DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE
        MATERIALS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE
        CORRECTED, OR THAT THIS SITE, INCLUDING THE SERVER THAT MAKES IT
        AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. IN ADDITION,
        PARENT DAY OUT SHALL NOT BE LIABLE TO YOU OR ANYONE ELSE FOR ANY LOSS OR
        INJURY CAUSED IN WHOLE OR PART BY CONTINGENCIES BEYOND ITS CONTROL IN
        PROCURING, COMPILING, INTERPRETING, REPORTING OR DELIVERING THE SERVICE
        AND INFORMATION THROUGH THE WEBSITE AND YOU ASSUME THE ENTIRE COST OF
        ALL NECESSARY MAINTENANCE, REPAIR OR CORRECTION ARISING FROM ANY SUCH
        LOSS OR INJURY.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Modification
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out shall have the right, at its sole discretion, to make
        improvements and/or changes in any aspect of the website at any time and
        does not accept any responsibility for the effects these alterations may
        have. Parent Day Out also reserves the right to change, modify, add or
        remove terms of this agreement at any time without notice. You agree to
        review this Agreement periodically since such changes, modifications,
        additions or deletions shall be effective immediately upon posting and
        your subsequent use after such posting shall conclusively be deemed to
        be acceptance by you of such changes, modifications or deletions.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Miscellaneous
      </Typography>
      <Typography variant="body1" paragraph>
        This Agreement shall be deemed to include all other notices, policies,
        disclaimers, and other terms contained in this website; provided,
        however, that in the event of a conflict between such other terms and
        the terms of this Agreement, the terms of this Agreement shall control.
        This Agreement has been made in and shall be construed and enforced in
        accordance with Delaware law without regard to any conflict of law
        principles. Any claim or dispute between you and Parent Day Out,
        including any action to enforce this Agreement shall be brought in the
        federal or state courts located in Delaware, and you agree to the
        exclusive and personal jurisdiction of these courts. If any provision is
        deemed to be unlawful or unenforceable, that shall not affect the
        validity and enforceability of the remaining provisions.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Copyright Information
      </Typography>
      <Typography variant="body1" paragraph>
        Except where noted otherwise, all website contents Copyright © 2018
        Parent Day Out. All rights reserved under United States and foreign law.
        No part of the materials on the website, including but not limited to
        the text, graphics and html code, may be reproduced or transmitted in
        any form by any means without the advance written permission from Parent
        Day Out.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Arbitration Agreement
      </Typography>
      <Typography variant="body1" paragraph>
        By agreeing to the Terms, you agree that you are required to resolve any
        claim that you may have against Parent Day Out on an individual basis in
        arbitration, as set forth in this Arbitration Agreement. This will
        preclude you from bringing any class, collective, or representative
        action against Parent Day Out, and also preclude you from participating
        in or recovering relief under any current or future class, collective,
        consolidated, or representative action brought against Parent Day Out by
        someone else.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Agreement to Binding Arbitration Between You and Parent Day Out
      </Typography>
      <Typography variant="body1" paragraph>
        You and Parent Day Out agree that any dispute, claim or controversy
        arising out of or relating to (a) these Terms or the existence, breach,
        termination, enforcement, interpretation or validity thereof, or (b)
        your access to or use of the Services at any time, whether before or
        after the date you agreed to the Terms, will be settled by binding
        arbitration between you and Parent Day Out, and not in a court of law.
      </Typography>
      <Typography variant="body1" paragraph>
        You acknowledge and agree that you and Parent Day Out are each waiving
        the right to a trial by jury or to participate as a plaintiff or class
        member in any purported class action or representative proceeding.
        Unless both you and Parent Day Out otherwise agree in writing, any
        arbitration will be conducted only on an individual basis and not in a
        class, collective, consolidated, or representative proceeding. However,
        you and Parent Day Out each retain the right to bring an individual
        action in small claims court and the right to seek injunctive or other
        equitable relief in a court of competent jurisdiction to prevent the
        actual or threatened infringement, misappropriation or violation of a
        party's copyrights, trademarks, trade secrets, patents or other
        intellectual property rights.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Rules and Governing Law
      </Typography>
      <Typography variant="body1" paragraph>
        The arbitration will be administered by the American Arbitration
        Association ("AAA") in accordance with the AAA’s Consumer Arbitration
        Rules and the Supplementary Procedures for Consumer Related Disputes
        (the "AAA Rules") then in effect, except as modified by this Arbitration
        Agreement. The AAA Rules are available at{" "}
        <a href="https://www.adr.org/arb_med">www.adr.org/arb_med</a> or by
        calling the AAA at 1-800-778-7879.
      </Typography>
      <Typography variant="body1" paragraph>
        The parties agree that the arbitrator (“Arbitrator”), and not any
        federal, state, or local court or agency, shall have exclusive authority
        to resolve any disputes relating to the interpretation, applicability,
        enforceability or formation of this Arbitration Agreement, including any
        claim that all or any part of this Arbitration Agreement is void or
        voidable. The Arbitrator shall also be responsible for determining all
        threshold arbitrability issues, including issues relating to whether the
        Terms are unconscionable or illusory and any defense to arbitration,
        including waiver, delay, laches, or estoppel.
      </Typography>
      <Typography variant="body1" paragraph>
        Notwithstanding any choice of law or other provision in the Terms, the
        parties agree and acknowledge that this Arbitration Agreement evidences
        a transaction involving interstate commerce and that the Federal
        Arbitration Act, 9 U.S.C. § 1 et seq. (“FAA”), will govern its
        interpretation and enforcement and proceedings pursuant thereto. It is
        the intent of the parties that the FAA and AAA Rules shall preempt all
        state laws to the fullest extent permitted by law. If the FAA and AAA
        Rules are found to not apply to any issue that arises under this
        Arbitration Agreement or the enforcement thereof, then that issue shall
        be resolved under the laws of the state of California.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Process
      </Typography>
      <Typography variant="body1" paragraph>
        A party who desires to initiate arbitration must provide the other party
        with a written Demand for Arbitration as specified in the AAA Rules.
        (The AAA provides a form Demand for Arbitration - Consumer Arbitration
        Rules at <a href="https://www.adr.org/">www.adr.org</a>or by calling the
        AAA at 1-800-778-7879). The Arbitrator will be either (1) a retired
        judge or (2) an attorney specifically licensed to practice law in the
        state of New Jersey and will be selected by the parties from the AAA's
        roster of consumer dispute arbitrators. If the parties are unable to
        agree upon an Arbitrator within seven (7) days of delivery of the Demand
        for Arbitration, then the AAA will appoint the Arbitrator in accordance
        with the AAA Rules.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Location and Procedure
      </Typography>
      <Typography variant="body1" paragraph>
        Unless you and Parent Day Out otherwise agree, the arbitration will be
        conducted in the county where you reside. If your claim does not exceed
        $10,000, then the arbitration will be conducted solely on the basis of
        documents you and Parent Day Out submit to the Arbitrator, unless you
        request a hearing or the Arbitrator determines that a hearing is
        necessary. If your claim exceeds $10,000, your right to a hearing will
        be determined by the AAA Rules. Subject to the AAA Rules, the Arbitrator
        will have the discretion to direct a reasonable exchange of information
        by the parties, consistent with the expedited nature of the arbitration.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Arbitrator's Decision
      </Typography>
      <Typography variant="body1" paragraph>
        The Arbitrator will render an award within the time frame specified in
        the AAA Rules. Judgment on the arbitration award may be entered in any
        court having competent jurisdiction to do so. The Arbitrator may award
        declaratory or injunctive relief only in favor of the claimant and only
        to the extent necessary to provide relief warranted by the claimant's
        individual claim. An Arbitrator’s decision shall be final and binding on
        all parties. An Arbitrator’s decision and judgment thereon shall have no
        precedential or collateral estoppel effect. If either party prevails in
        arbitration, that party will be entitled to an award of attorneys' fees
        and expenses, to the extent provided under applicable law.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Fees
      </Typography>
      <Typography variant="body1" paragraph>
        Your responsibility to pay any AAA filing, administrative and arbitrator
        fees will be solely as set forth in the AAA Rules.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Severability and Survival
      </Typography>
      <Typography variant="body1">
        If any portion of this Arbitration Agreement is found to be
        unenforceable or unlawful for any reason, (1) the unenforceable or
        unlawful provision shall be severed from these Terms; (2) severance of
        the unenforceable or unlawful provision shall have no impact whatsoever
        on the remainder of the Arbitration Agreement or the parties’ ability to
        compel arbitration of any remaining claims on an individual basis
        pursuant to the Arbitration Agreement; and (3) to the extent that any
        claims must therefore proceed on a class, collective, consolidated, or
        representative basis, such claims must be litigated in a civil court of
        competent jurisdiction and not in arbitration, and the parties agree
        that litigation of those claims shall be stayed pending the outcome of
        any individual claims in arbitration.
      </Typography>
    </div>
  );
};

export default IndexTermsPage;
