import React from "react";

import Typography from "@ui-kit/Typography";
import { useCss } from "../styles";

const PaymentsTermsPage: React.FunctionComponent = () => {
  const css = useCss();

  return (
    <div css={css.legalPage}>
      <Typography variant="h3" gutterBottom align="center">
        Payments terms of service
      </Typography>

      <Typography variant="body1" paragraph>
        By using Parent Day Out Payment Services, you agree to comply with and
        be bound by the Payments Terms of Service.
      </Typography>
      <Typography variant="body1" paragraph>
        The Parent Day Out Terms of Use ("Parent Day Out Terms”) separately
        govern your use of the Parent Day Out platform.
      </Typography>
      <Typography variant="body1" paragraph>
        Our collection and use of personal information in connection with your
        access to and use of the Payment Services is described in Parent Day
        Out's Privacy Policy.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Scope and Use of the Payment Services
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out Payments provides payments services, including payment
        collection services, payments and payouts, in connection with and
        through the Parent Day Out Platform (“Payment Services”). Please note
        that “Payment Services” include Parent Day Out Payments services
        associated with School Listings provided through Parent Day Out’s
        Listing Program.
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out Payments may restrict the availability of the Payment
        Services, or certain services or features thereof, to carry out
        maintenance measures that ensure the proper or improved functioning of
        the Payment Services. Parent Day Out Payments may improve, enhance and
        modify the Payment Services and introduce new Payment Services from time
        to time.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Modification of these Payments Terms
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out Payments reserves the right to modify these Payments
        Terms at any time in accordance with this provision. If we make changes
        to these Payments Terms, we will post the revised Payments Terms on the
        Parent Day Out Platform.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Account Registration
      </Typography>
      <Typography variant="body1" paragraph>
        In order to use the Payment Services, you must have a Parent Day Out
        Account in good standing. If you or Parent Day Out closes your Parent
        Day Out Account for any reason, you will no longer be able to use the
        Payment Services.
      </Typography>
      <Typography variant="body1" paragraph>
        You acknowledge and agree that anyone you authorize to use your Parent
        Day Out Account may use the Payment Services on your behalf and that you
        will be responsible for any payments made by such person.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Eligibility, Member Verification
      </Typography>
      <Typography variant="body1" paragraph>
        If you are agreeing to these Payments Terms on behalf of a company or
        other legal entity, you represent and warrant that you have the
        authority to bind that company or other legal entity to these Payments
        Terms and, in such event, “you” and “your” will refer and apply to that
        company or other legal entity.
      </Typography>
      <Typography variant="body1" paragraph>
        We may make inquiries we consider necessary to help verify or check your
        identity or prevent fraud. Towards this end, you authorize Parent Day
        Out Payments to screen you against third party databases or other
        sources and request reports from service providers. In some
        jurisdictions, we have a legal obligation to collect identity
        information to comply with anti-money laundering regulations.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Referrals and Promotional Codes
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out may, in its sole discretion, create referral and/or
        promotional codes ("Promo Codes") that may be redeemed for discounts on
        future Services and/or a Third Party Provider's services, or other
        features or benefits related to the Services and/or a Third Party
        Provider's services, subject to any additional terms that Parent Day Out
        establishes. You agree that Promo Codes: (i) must be used for the
        intended audience and purpose, and in a lawful manner; (ii) may not be
        duplicated, sold or transferred in any manner, or made available to the
        general public (whether posted to a public form or otherwise), unless
        expressly permitted by Parent Day Out; (iii) may be disabled by Parent
        Day Out at any time for any reason without liability to Parent Day Out;
        (iv) may only be used pursuant to the specific terms that Parent Day Out
        establishes for such Promo Code; (v) are not valid for cash; and (vi)
        may expire prior to your use. Parent Day Out reserves the right to
        withhold or deduct credits or other features or benefits obtained
        through the use of the referral system or Promo Codes by you or any
        other user in the event that Parent Day Out determines or believes that
        the use of the referral system or use or redemption of the Promo Code
        was in error, fraudulent, illegal, or otherwise in violation of Parent
        Day Out’s Terms.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Key Definitions
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>“Payment Method”</strong> means a financial instrument that you
        have added to your Parent Day Out Account, such as a credit card
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>“Payout”</strong> means a payment initiated by Parent Day Out
        Payments to a partner facility for performed in connection with the
        Parent Day Out Platform.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>“Payout Method”</strong> means a financial instrument that you
        have added to your Parent Day Out Account, such as a direct deposit
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>"Service Fee"</strong> means the portion of each Booking Fee
        that is retained by Parent Day Out Payments. The service fee may vary
        based on specific agreements between Parent Day Out and the partner
        facility.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Payment Methods and Payout Methods
      </Typography>
      <Typography variant="body1" paragraph>
        When you add a Payment Method or Payout Method to your Parent Day Out
        Account, you will be asked to provide customary billing information such
        as name, billing address, and financial instrument information. You must
        provide accurate, current, and complete information when adding a
        Payment Method or Payout Method, and it is your obligation to keep your
        Payment Method and Payout Method up-to-date at all times.
      </Typography>
      <Typography variant="body1" paragraph>
        When you add or use a new Payment Method, Parent Day Out Payments may
        verify the Payment Method by authorizing a nominal amount or by
        authenticating your account via a third-party payment service provider.
        For further verification, we may also (i) authorize your Payment Method
        for one or two additional nominal amounts and ask you to confirm these
        amounts, or (ii) require you to upload a billing statement. We may, and
        retain the right to, initiate refunds of these amounts from your Payout
        Method. When you add a Payment Method during checkout, we will
        automatically save that Payment Method to your Parent Day Out account so
        it can be used for a future transaction.
      </Typography>
      <Typography variant="body1" paragraph>
        You authorize Parent Day Out Payments to store your Payment Method
        information and charge your Payment Method as outlined in these Payments
        Terms. If your Payment Method’s account information changes (e.g.,
        account number, routing number, expiration date) as a result of
        re-issuance or otherwise, we may acquire that information from our
        financial services partner or your bank and update your Payment Method
        on file in your Parent Day Out Account.
      </Typography>
      <Typography variant="body1" paragraph>
        You are solely responsible for the accuracy and completeness of your
        Payment Method and Payout Method information. Parent Day Out Payments is
        not responsible for any loss suffered by you as a result of incorrect
        Payment Method or Payout Method information provided by you.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Payment Authorizations
      </Typography>
      <Typography variant="body1" paragraph>
        You authorize Parent Day Out Payments to collect from you amounts due
        pursuant to these Payment
      </Typography>
      <Typography variant="body1" paragraph>
        If, as a Partner facility, you cancel a confirmed booking, Parent Day
        Out Payments will be entitled to recover the amount of any such fees
        from you, including by subtracting such refund amount out from any
        future Payouts due to you.
      </Typography>
      <Typography variant="body1" paragraph>
        You agree that in the event you have already been paid, Parent Day Out
        Payments will be entitled to recover the amount of any such refund from
        you, including by subtracting such refund amount out from any future
        Payouts due to you.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Financial terms for partner facilities
      </Typography>
      <Typography variant="body1" paragraph>
        In order to receive a Payout you must have a valid Payout Method linked
        to your Parent Day Out Account
      </Typography>
      <Typography variant="body1" paragraph>
        Partner facilities participating in Parent Day Out Services are
        typically paid weekly, with payments being deposited automatically via
        direct deposit, as per banking information provided by the facility
        ("Payout Method").
      </Typography>
      <Typography variant="body1" paragraph>
        The time it takes to receive Payouts once released by Parent Day Out
        Payments may depend upon the Payout Method you select and the Payout
        Method provider’s processing schedule. Parent Day Out Payments may delay
        or cancel any Payout for purposes of preventing unlawful activity or
        fraud, risk assessment, security, or investigation.
      </Typography>
      <Typography variant="body1" paragraph>
        In the event of a cancellation of a confirmed booking, Parent Day Out
        Payments will remit a Payout of any portion of the Total Fees due to you
        under the applicable cancellation policy.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Financial terms for guests
      </Typography>
      <Typography variant="body1" paragraph>
        All charges and payments will be enabled by Parent Day Out using the
        preferred payment method designated in your Account. If your primary
        Account payment method is determined to be expired, invalid or otherwise
        not able to be charged, you agree that Parent Day Out may use a
        secondary payment method in your Account, if available. Charges paid by
        you are final and non-refundable, unless otherwise determined by Parent
        Day Out.
      </Typography>
      <Typography variant="body1" paragraph>
        You authorize Parent Day Out Payments to charge your Payment Method the
        Total Fees for any booking requested in connection with your Parent Day
        Out Account. Parent Day Out Payments will collect the Total Fees in the
        manner agreed between you and Parent Day Out Payments via the Parent Day
        Out Platform. Parent Day Out Payments will generally collect the Total
        Fees before your reservation date. Fess due will be displayed via the
        Parent Day Out Platform and you agree to pay such fees by selecting the
        payment option. If Parent Day Out Payments is unable to collect the
        Total Fees as scheduled, Parent Day Out Payments will collect the Total
        Fees at a later point. Once the payment transaction for your requested
        booking is successfully completed, you will receive a confirmation
        email.
      </Typography>
      <Typography variant="body1" paragraph>
        When book a playdate, Parent Day Out Payments may also (i) obtain a
        pre-authorization via your Payment Method for the Total Fees,(ii) charge
        or authorize your Payment Method a nominal amount, to verify your
        Payment Method, or (iii) authenticate your account via a third-party
        payment service provider to verify your Payment Method.
      </Typography>
      <Typography variant="body1" paragraph>
        If a requested booking is cancelled the timing to receive the refund or
        for the pre-authorization to be released will vary based on the Payment
        Method and any applicable payment system (e.g., Visa, MasterCard, etc.)
        rules.
      </Typography>
      <Typography variant="body1" paragraph>
        You authorize Parent Day Out Payments to perform the Payment Method
        verification described in previous sections, and to charge your Payment
        Method for any bookings made in connection with your Parent Day Out
        Account. You hereby authorize Parent Day Out Payments to collect any
        amounts due by charging the Payment Method provided at checkout
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Refunds
      </Typography>
      <Typography variant="body1" paragraph>
        Any refunds or credits due pursuant to the Parent Day Out Terms will be
        initiated and remitted by Parent Day Out Payments.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Payment Processing Errors
      </Typography>
      <Typography variant="body1" paragraph>
        We will take steps to rectify any payment processing errors that we
        become aware of. These steps may include crediting or debiting (as
        appropriate) the same Payout Method or Payment Method used for the
        original Payout to or payment by you, so that you end up receiving or
        paying the correct amount. This may be performed by Parent Day Out
        Payments or a third party such as your financial institution.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Collections
      </Typography>
      <Typography variant="body1" paragraph>
        If Parent Day Out Payments is unable to collect any amounts you owe
        under these Payments Terms, Parent Day Out Payments may engage in
        collection efforts to recover such amounts from you. Parent Day Out
        Payments will deem any owed amounts overdue when: (a) for authorized
        charges, thirty (30) days have elapsed after Parent Day Out Payments
        first attempts to charge the Member’s Payment Method or the associated
        services have been provided, whichever is earlier; and (b) for{" "}
        withholdings from a Partner facilities’ future Payouts, thirty (30) days
        have elapsed after the adjustment is made to the Partner facilities’
        account or the associated services have been provided, whichever is
        earlier. Any overdue amounts not collected within thirty (30) days after
        they become overdue will be deemed to be in default.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Intellectual Property Ownership, Rights Notices
      </Typography>
      <Typography variant="body1" paragraph>
        The Payment Services are protected by copyright, trademark, and other
        laws of the United States and foreign countries. You acknowledge and
        agree that the Payment Services, including all associated intellectual
        property rights, are the exclusive property of Parent Day Out, Parent
        Day Out Payments and its licensors. You will not remove, alter or
        obscure any copyright, trademark, service mark or other proprietary
        rights notices incorporated in or accompanying the Payment Services. All
        trademarks, service marks, logos, trade names, and any other proprietary
        designations of Parent Day Out or Parent Day Out Payments used on or in
        connection with the Payment Services are trademarks or registered
        trademarks of Parent Day Out or Parent Day Out Payments in the United
        States and abroad. Trademarks, service marks, logos, trade names, and
        any other proprietary designations of third parties used on or in
        connection with Payment Services are used for identification purposes
        only and may be the property of their respective owners.
      </Typography>
      <Typography variant="body1" paragraph>
        You will not use, copy, adapt, modify, prepare derivative works based
        upon, distribute, license, sell, transfer, publicly display, publicly
        perform, transmit, broadcast, or otherwise exploit the Payment Services,
        except as expressly permitted in these Payments Terms. No licenses or
        rights are granted to you by implication or otherwise under any
        intellectual property rights owned or controlled by Parent Day Out,
        Parent Day Out Payments, or its licensors, except for the licenses and
        rights expressly granted in these Payments Terms.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Disclaimers
      </Typography>
      <Typography variant="body1" paragraph>
        If you choose to use the Payment Services, you do so voluntarily and at
        your sole risk. To the maximum extent permitted by law, the Payment
        Services are provided “as is”, without warranty of any kind, either
        express or implied.
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out Payments explicitly disclaims all liability for any act
        or omission of any Member or other third party. Parent Day Out Payments
        does not have any duties or obligations as agent for each Providing
        Member except to the extent expressly set forth in these Payments Terms,
        and any additional duties or obligations as may be implied by law are,
        to the maximum extent permitted by applicable law, expressly excluded.
      </Typography>
      <Typography variant="body1" paragraph>
        If we choose to conduct identity verification on any Member, to the
        extent permitted by applicable law, we disclaim warranties of any kind,
        either express or implied, that such checks will identify prior
        misconduct by a Member or guarantee that a Member will not engage in
        misconduct in the future.
      </Typography>
      <Typography variant="body1" paragraph>
        The foregoing disclaimers apply to the maximum extent permitted by law.
        You may have other statutory rights or warranties which cannot lawfully
        be excluded. However, the duration of any statutorily required
        warranties shall be limited to the maximum extent (if any) permitted by
        law.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Liability
      </Typography>
      <Typography variant="body1" paragraph>
        Except as provided herein, you acknowledge and agree that, to the
        maximum extent permitted by law, the entire risk arising out of your
        access to and use of the Payment Services remains with you. If you
        permit or authorize another person to use your Parent Day Out Account in
        any way, you are responsible for the actions taken by that person.
        Neither Parent Day Out Payments nor any other party involved in
        creating, producing, or delivering the Payment Services will be liable
        for any incidental, special, exemplary, or consequential damages,
        including lost profits, loss of data or loss of goodwill, service
        interruption, computer damage or system failure or the cost of
        substitute products or services, or for any damages for personal or
        bodily injury or emotional distress arising out of or in connection with
        (i) these Payments Terms, (ii) from the use of or inability to use the
        Payment Services, or (iii) from any communications, interactions, or
        meetings with other Members or other persons with whom you communicate,
        interact, transact, or meet with as a result of your use of the Payment
        Services, whether based on warranty, contract, tort (including
        negligence), product liability, or any other legal theory, and whether
        or not Parent Day Out Payments has been informed of the possibility of
        such damage, even if a limited remedy set forth herein is found to have
        failed of its essential purpose.
      </Typography>
      <Typography variant="body1" paragraph>
        Except for our obligations to pay amounts to applicable Members pursuant
        to these Payments Terms in no event will Parent Day Out Payments’
        aggregate liability arising out of or in connection with these Payments
        Terms and your use of the Payment Services. The limitations of damages
        set forth are fundamental elements of the basis of the bargain between
        Parent Day Out Payments and you. Some jurisdictions do not allow the
        exclusion or limitation of liability for consequential or incidental
        damages, so the above limitation may not apply to you. If you reside
        outside of the U.S., this does not affect Parent Day Out Payments’
        liability for death or personal injury arising from its negligence, nor
        for fraudulent misrepresentation, misrepresentation as to a fundamental
        matter, or any other liability which cannot be excluded or limited under
        applicable law.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Indemnification
      </Typography>
      <Typography variant="body1" paragraph>
        You agree to release, defend (at Parent Day Out Payments’ option),
        indemnify, and hold Parent Day Out Payments and its affiliates and
        subsidiaries, and their officers, directors, employees, and agents,
        harmless from and against any claims, liabilities, damages, losses, and
        expenses, including, without limitation, reasonable legal and accounting
        fees, arising out of or in any way connected with (i) your breach of
        these Payments Terms; (ii) your improper use of the Payment Services;
        (iii) your breach of any laws, regulations, or third-party rights.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        Termination, Suspension, and other Measures
      </Typography>
      <Typography variant="body1" paragraph>
        If you cancel your Parent Day Out Account as a Member, Parent Day Out
        Payments will initiate a refund for any confirmed booking(s) based on
        the associated cancellation policy.
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out Payments may immediately, without notice terminate this
        Agreement if (i) you have materially breached your obligations under
        this Agreement; (ii) you have provided inaccurate, fraudulent, outdated,
        or incomplete information; (iii) you have violated applicable laws,
        regulations, or third-party rights; or (iv) Parent Day Out Payments
        believes in good faith that such action is reasonably necessary to
        protect other Members, Parent Day Out, Parent Day Out Payments, or third
        parties (for example in the case of fraudulent behavior of a Member).
      </Typography>
      <Typography variant="body1" paragraph>
        In addition, Parent Day Out Payments may limit or temporarily or
        permanently suspend your use of or access to the Payment Services (i) to
        comply with applicable law, or the order or request of a court, law
        enforcement, or other administrative agency or governmental body, or if
        (ii) you have breached these Payments Terms, the Parent Day Out Terms of
        Use, applicable laws, regulations or third-party rights, (iii) you have
        provided inaccurate, fraudulent, outdated, or incomplete information
        regarding a Payment Method or Payout Method, or (iv) Parent Day Out
        Payments believes in good faith that such action is reasonably necessary
        to protect the personal safety or property of Parent Day Out, its
        Members, Parent Day Out Payments, or third parties, or to prevent fraud
        or other illegal activity.
      </Typography>
      <Typography variant="body1" paragraph>
        In case of non-material breaches and where appropriate, you will be
        given notice of any measure by Parent Day Out Payments and an
        opportunity to resolve the issue to Parent Day Out Payments' reasonable
        satisfaction.
      </Typography>
      <Typography variant="body1" paragraph>
        If you are a Partner facility and we take any of the measures described
        in this Section we may refund your Guests in full for any and all
        confirmed bookings, irrespective of preexisting cancellation policies,
        and you will not be entitled to any compensation for pending or
        confirmed bookings that were cancelled.
      </Typography>
      <Typography variant="body1" paragraph>
        If your access to or use of the Payment Services has been limited or
        this Agreement has been terminated by us, you may not register a new
        Parent Day Out Account or attempt to access and use the Payment Services
        through other an Parent Day Out Account of another Member.
      </Typography>
      <Typography variant="body1" paragraph>
        If you or we terminate this Agreement, the clauses of these Payments
        Terms that reasonably should survive termination of these Payments Terms
        will remain in effect.
      </Typography>

      <Typography variant="body1" paragraph bolded>
        General Provisions
      </Typography>
      <Typography variant="body1" paragraph>
        Except as they may be supplemented by additional terms and conditions,
        policies, guidelines, or standards, these Payments Terms constitute the
        entire Agreement between Parent Day Out Payments and you regarding the
        subject matter hereof, and supersede any and all prior oral or written
        understandings or agreements between Parent Day Out Payments and you
        regarding the Payment Services.
      </Typography>
      <Typography variant="body1" paragraph>
        No joint venture, partnership, employment, or agency relationship exists
        between you or Parent Day Out Payments as a result of this Agreement or
        your use of the Payment Services.
      </Typography>
      <Typography variant="body1" paragraph>
        If any provision of these Payments Terms is held to be invalid or
        unenforceable, such provision will be struck and will not affect the
        validity and enforceability of the remaining provisions.
      </Typography>
      <Typography variant="body1" paragraph>
        Parent Day Out Payments’ failure to enforce any right or provision in
        these Payments Terms will not constitute a waiver of such right or
        provision unless acknowledged and agreed to by us in writing. Except as
        expressly set forth in these Payments Terms, the exercise by either
        party of any of its remedies under these Payments Terms will be without
        prejudice to its other remedies under these Payments Terms or otherwise
        permitted under law.
      </Typography>
      <Typography variant="body1" paragraph>
        You may not assign, transfer, or delegate this Agreement and your rights
        and obligations hereunder without Parent Day Out Payments’ prior written
        consent. Parent Day Out Payments may without restriction assign,
        transfer, or delegate this Agreement and any rights and obligations, at
        its sole discretion. Your right to terminate this Agreement at any time
        remains unaffected.
      </Typography>
      <Typography variant="body1">
        This Agreement does not and is not intended to confer any rights or
        remedies upon any person other than the parties. Notwithstanding the
        foregoing, the parties agree that the payment card networks are
        third-party beneficiaries of this Agreement for purposes of enforcing
        provisions related to payments, but that their consent or agreement is
        not necessary for any changes or modifications to this Agreement.
      </Typography>
    </div>
  );
};

export default PaymentsTermsPage;
