import React, { useState, Fragment } from "react";
import { css as emotionCss } from "@emotion/css";
import Helmet from "react-helmet";
import {
  Switch,
  Route,
  Link,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import Header from "@components/Header";
import AuthorizationPage from "./Authorization";
import PaymentsPage from "./Payments";
import PrivacyPage from "./Privacy";
import OndemandPage from "./Ondemand";
import OtherPage from "./Other";
import IndexPage from "./Main";
import { useCss } from "./styles";
import Button from "@ui-kit/Button";
import Footer from "@components/FooterNew";
import META from "./meta";
import { useViewport } from "@hooks/useViewport";

const MENU_ITEMS = [
  {
    link: "/terms",
    name: "Terms of use",
  },
  {
    link: "/terms/privacy",
    name: "Privacy policy",
  },
  {
    link: "/terms/payments",
    name: "Payments terms of service",
  },
  {
    link: "/terms/authorization",
    name: "HIPAA authorization",
  },
  {
    link: "/terms/parentdayout",
    name: "Childcare booking service",
  },
  {
    link: "/terms/other",
    name: "Other terms & conditions",
  },
];

const TermsPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { isMobile } = useViewport();
  const [menuAnchorElement, setMenuAnchorElement] =
    useState<HTMLElement | null>(null);
  const css = useCss();

  return (
    <Fragment>
      <div css={css.root} data-test="terms-page">
        <Header fixed />
        <Helmet {...META} />
        {isMobile ? (
          <div css={css.mobileMenuWrapper}>
            <Button
              aria-owns={menuAnchorElement ? "menu" : undefined}
              aria-haspopup="true"
              variant="outlined"
              data-test="mobile-menu-trigger"
              onClick={(event) => setMenuAnchorElement(event.currentTarget)}
              fullWidth
              className="trigger-btn"
            >
              {MENU_ITEMS.find((i) => i.link === location.pathname)?.name}
              <ArrowDropDownIcon />
            </Button>
            <Menu
              id="menu"
              anchorEl={menuAnchorElement}
              open={Boolean(menuAnchorElement)}
              onClose={() => setMenuAnchorElement(null)}
              classes={{
                paper: emotionCss({
                  width: "100%",
                }),
              }}
              data-test="terms-link-menu"
            >
              {MENU_ITEMS.map((i) => (
                <MenuItem
                  onClick={() => {
                    setMenuAnchorElement(null);
                    history.push(i.link);
                  }}
                  key={i.link}
                >
                  {i.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        ) : (
          <div css={css.menuWrapper}>
            <ul css={css.menu}>
              {MENU_ITEMS.map((i) => (
                <li key={i.link}>
                  <Link to={i.link}>{i.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div css={css.content}>
          <Switch>
            <Route exact path="/terms" component={IndexPage} />
            <Route path="/terms/authorization" component={AuthorizationPage} />
            <Route path="/terms/privacy" component={PrivacyPage} />
            <Route path="/terms/payments" component={PaymentsPage} />
            <Route path="/terms/parentdayout" component={OndemandPage} />
            <Route path="/terms/other" component={OtherPage} />
            <Redirect to="/terms" />
          </Switch>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default TermsPage;
